import React from "react";
import "../css/ProfileTutorCard.css";

export default function TutorProfileAboutmeCard({ tutor }) {
  return (
    <div>
      <p>{tutor.experience || "No about me information provided."}</p>{" "}
      {/* Muestra el texto o un mensaje predeterminado */}
    </div>
  );
}
