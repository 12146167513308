import React from "react";
import Navbar from "../components/NavBar";
import Footer from "../components/HomeFooter";
import DisabilityHero from "../components/DisabilityHero";

const LandingPage = () => {
  return (
    <div>
      <Navbar />
      <DisabilityHero />
      <Footer />
    </div>
  );
};

export default LandingPage;