import React from "react";
import { useNavigate } from "react-router-dom";

const DisabilityHero = () => {
  const navigate = useNavigate();

  const handleStartTest = () => {
    navigate("/exercise");
  };
  return (
    <section className="HeroContainer">
      <div className="HeroBackground">
        <div className="HeroImage"></div>
        <div className="HeroGradient"></div>
      </div>
      <div className="HeroContent">
        <h1>Learning Disabilities Test</h1>
        <h2>
          Identify Learning Disabilities Early with Our Interactive Assessment
        </h2>
        <p>
          This test is designed to help identify specific learning disabilities
          in children. By assessing areas like reading, writing, math,
          attention, and more, we can provide insight into your child's
          strengths and challenges. Please ensure your child takes the test
          independently for accurate results.
        </p>
        <button className="HeroButton" onClick={handleStartTest}>
          Start the Test Now
        </button>
      </div>
    </section>
  );
};

export default DisabilityHero;
