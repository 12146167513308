import React from "react";
import Navbar from "../components/NavBar";
import ContactForm from "../components/ContactForm";
import HomeFooter from "../components/HomeFooter";

const Contact = () => {
    return ( 
        <div>
             <Navbar />
            <ContactForm></ContactForm>
            <HomeFooter></HomeFooter>
        </div>
     );
}
 
export default Contact;