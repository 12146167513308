import React from 'react';
import '../css/Home.css';
import Navbar from "../components/NavBar";
import Purpose from "../components/Purpose1";
import Discover from "../components/Discover";
import HomeStories from "../components/HomeStories";
import HomeAboutUs from '../components/HomeAboutUS'
import HomeFooter from "../components/HomeFooter";

const Home = () => {
    return (
        <div>
            <header>
                <Navbar />
            </header>
            <main>
                <div className="home">
                    <Purpose />
                    <Discover />
                    <HomeStories />
                    <HomeAboutUs />
                    <HomeFooter />                       
                </div>
            </main>
        </div>
    );
}

export default Home;
