import React from "react";
import Navbar from "../components/NavBar";
import HomeFooter from "../components/HomeFooter";
import DisabilityHeroDyslexia from "../components/DisabilityHeroDyslexia";

const DisabilityTestDyslexia = () => {
    return ( 
        <>
       <Navbar />
        <DisabilityHeroDyslexia/>
        <HomeFooter/>
        </>
     );
}
 
export default DisabilityTestDyslexia;