import React from "react";
import ParulImg from "../assets/images/Parul.svg";
import MonicaImg from "../assets/images/Monica_ 2.svg";
import MiniImg from "../assets/images/Mini-1 3.svg";
import CarlaImg from "../assets/images/Carla.svg";
import CarlosImg from "../assets/images/Carlos_.svg";
import SoudImg from "../assets/images/Soud_.svg";
import ShinuImg from "../assets/images/Shinu.svg";
import GibranImg from "../assets/images/Gibran_ 2.svg";
import MansurImg from "../assets/images/Mansur_ 2.svg";

const TeamMembers = () => {
  return (
    <section className="team-section">
      <h2>Our team members</h2>
      <h3>Team Synergy</h3>
      <div className="team-grid">
        <div className="team-member">
          <img src={ParulImg} alt="Parul" className="team-image" />
          <h4>Parul</h4>
          <p className="role">Project Manager/UI UX Jr. Designer</p>
          <p className="description">
            "Since last project, I have built a larger experience on Figma,
            especially on wireframes and prototypes, and worked on the front-end
            as well, supporting both Design and Development teams. This time, I
            worked on the UX/UI and creation as a designer, also contributed
            guiding the team to achieve an efficient result as a Project
            Leader."
          </p>
          <div className="linkedin-container">
            <i className="fa-brands fa-linkedin"></i>
            <a
              href="https://www.linkedin.com/in/parul-awal-3a396b2a4/"
              className="linkedin-link"
            >
              Parul Awal
            </a>
          </div>
        </div>

        <div className="team-member">
          <img src={MonicaImg} alt="Monica Mesquita" className="team-image" />
          <h4>Monica Mesquita</h4>
          <p className="role">Lead Designer/UI UX Jr. Designer</p>
          <p className="description">
            "I have a background in internal and corporate communication. As a
            design student, I have contributed to graphic and UI/UX design,
            being the design leader. For this project, I aim to use my current
            knowledge in tools like Figma to guide and help my colleagues, while
            also learning and collaborating with them to achieve the best
            results."
          </p>
          <div className="linkedin-container">
            <i className="fa-brands fa-linkedin"></i>
            <a
              href="https://www.linkedin.com/in/monica-mesquita-6082a135/"
              className="linkedin-link"
            >
              Monica Mesquita
            </a>
          </div>
        </div>

        <div className="team-member">
          <img src={CarlaImg} alt="Carla dos Santos" className="team-image" />
          <h4>Carla dos Santos</h4>
          <p className="role">UI UX Jr. Designer</p>
          <p className="description">
            "Although I'm new to design, I have always been attracted to visual
            creativity, which sparked my interest in this field. My business
            background has sharpened my eye for detail and problem-solving
            skills. I’m excited to bring into design. In this project, I have
            contributed with UI/UX design to achieve the platform goals."
          </p>
          <div className="linkedin-container">
            <i className="fa-brands fa-linkedin"></i>
            <a
              href="https://www.linkedin.com/in/carla-beatriz-dos-santos-598b1128b/"
              className="linkedin-link"
            >
              Carla dos Santos
            </a>
          </div>
        </div>

        <div className="team-member">
          <img src={MiniImg} alt="Mini Balakrishnan" className="team-image" />
          <h4>Mini Balakrishnan</h4>
          <p className="role">UI UX Jr. Designer</p>
          <p className="description">
            "With 17 years of teaching experience in India, I've created
            supportive learning environments for students across various
            subjects. In this project, I guided the team as a UI/UX designer,
            focusing on accessible features and simplifying complex ideas to
            develop user-friendly solutions that meet our project's goals."
          </p>
          <div className="linkedin-container">
            <i className="fa-brands fa-linkedin"></i>
            <a
              href="https://www.linkedin.com/in/mini-balakrishnan-062301232/"
              className="linkedin-link"
            >
              Mini Balakrishnan
            </a>
          </div>
        </div>

        <div className="team-member">
          <img src={ShinuImg} alt="Shinu George" className="team-image" />
          <h4>Shinu George</h4>
          <p className="role">UI UX Jr. Designer</p>
          <p className="description">
            "I have 8 years of project management experience, particularly with
            front-end design and development teams. Currently, I’m enhancing my
            web design skills through a designer stream, expanding my knowledge
            of technical skills. For this project, I’m involved in all stages to
            ensure a seamless user experience and alignment with business
            goals."
          </p>
          <div className="linkedin-container">
            <i className="fa-brands fa-linkedin"></i>
            <a
              href="https://www.linkedin.com/in/shinu-george"
              className="linkedin-link"
            >
              Shinu George
            </a>
          </div>
        </div>

        <div className="team-member">
          <img src={SoudImg} alt="Soud Haroon" className="team-image" />
          <h4>Soud Haroon</h4>
          <p className="role">Lead Developer/Mobile App Developer</p>
          <p className="description">
            "I have a background as a Mobile Application developer, specifically
            developing mobile apps using Flutter framework. For this project, I
            worked as a back-end developer and Development Lead. About the
            tools, I worked with NodeJS for logic/operations and MongoDB for
            handling user data."
          </p>
          <div className="linkedin-container">
            <i className="fa-brands fa-linkedin"></i>
            <a
              href="https://www.linkedin.com/in/soudharoon/"
              className="linkedin-link"
            >
              Soud Haroon
            </a>
          </div>
        </div>

        <div className="team-member">
          <img src={CarlosImg} alt="Carlos Borsato" className="team-image" />
          <h4>Carlos Borsato</h4>
          <p className="role">Jr. Front-end Developer</p>
          <p className="description">
            "I’m a Mechanical Engineer, and although new in design, I enjoy
            building engaging and user-friendly interfaces. Although I am fairly
            new, I’ve already built skills through the start. In this project, I
            focused on HTML and CSS, and bringing our API to life with a smooth
            design on the front-end functionality."
          </p>
          <div className="linkedin-container">
            <i className="fa-brands fa-linkedin"></i>
            <a
              href="https://www.linkedin.com/in/carloshenriqueborsato/"
              className="linkedin-link"
            >
              Carlos Borsato
            </a>
          </div>
        </div>

        <div className="team-member">
          <img src={GibranImg} alt="Gibran Oliva" className="team-image" />
          <h4>Gibran Oliva</h4>
          <p className="role">QA Tester Developer</p>
          <p className="description">
            "I have experience as a QA tester for mobile games. In this project,
            my focus is on back-end development, working with Node.js and
            Express.js to handle server-side logic and database management. I
            also integrated MongoDB for database operations and focused on
            making our app efficient and bug-free on both the front-end and
            back-end."
          </p>
          <div className="linkedin-container">
            <i className="fa-brands fa-linkedin"></i>
            <a
              href="https://www.linkedin.com/in/gibran-oliva-leão-288884115/"
              className="linkedin-link"
            >
              Gibran Oliva
            </a>
          </div>
        </div>

        <div className="team-member">
          <img src={MansurImg} alt="Ahmed Mansur" className="team-image" />
          <h4>Ahmed Mansur</h4>
          <p className="role">Front-end Developer</p>
          <p className="description">
            "In this project, I’m working as a front-end developer specializing
            in React JS and CSS, creating responsive, user-friendly interfaces.
            I’m also gaining experience in database integration to enhance both
            front-end user interaction and back-end functionality, ensuring a
            more reliable and efficient platform for users."
          </p>
          <div className="linkedin-container">
            <i className="fa-brands fa-linkedin"></i>
            <a
              href="https://www.linkedin.com/in/mansur-ahmed-29131373/"
              className="linkedin-link"
            >
              Mansur Ahmed
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamMembers;
