import React from 'react';
import Navbar from "../components/NavBar";
import ParentProfileCard from "../components/ParentProfileCard";
import HomeFooter from "../components/HomeFooter";
import '../css/ParentProfile.css'; 

const ParentProfile = () => {
  return (
    <div className='ParentProfilePage'>
      <header>
        <Navbar />
      </header>
      <main>
        <ParentProfileCard />

      </main>
      <footer>
        <HomeFooter /> 
      </footer>
    </div>
  );
};

export default ParentProfile;