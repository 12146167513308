import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import TrainingChildCard from "../components/TrainingChildCard";
import ProfileTutorDetailsCard from "../components/ProfileTutorDetailsCard";
import ProfileTutorInfoCard from "../components/ProfileTutorInfoCard";
import TutorProfileAboutmeCard from "../components/TutorProfileAboutmeCard";
import ButtonStudentsList from "./Button/ButtonStudentsList";
import ButtonEditTutorProfile from "./Button/ButtonEditTutorProfile";

const ProfileTutorCard = ({ tutor }) => {
  const formatAvailability = () => {
    if (typeof tutor.availability === "string") {
      return tutor.availability;
    } else if (Array.isArray(tutor.availability)) {
      return tutor.availability.map((slot, index) => (
        <div key={index}>
          {slot.day}: {slot.start} - {slot.end}
        </div>
      ));
    }
    return "N/A";
  };
  return (
    <div className="profile-tutor-maxwidth">
      <div className="profile-container">
        <h2 className="tutor-Montserrat">Tutor Profile</h2>
        <div className="profile-card">
          <div className="profile-tutor-details">
            <ProfileTutorDetailsCard tutor={tutor} />
          </div>
          <div className="tutor-profile-buttom-students">
            <ButtonStudentsList />
          </div>
          <div className="profile-tutor-info">
            <ProfileTutorInfoCard tutor={tutor}
            />
          </div>
          <div className="tutor-profile-aboutme">
            <TutorProfileAboutmeCard tutor={tutor} />
          </div>
          <div className="profile-tutor-availability">
            <h4 className="tutor-Montserrat">Available days and Timings</h4>
            <div className="tutor-available-times">
              <div className="tutor-available-time">
                <FontAwesomeIcon icon={faClock} />
                <p className="tutor-Montserrat">
                  <strong>Availability:</strong> {formatAvailability()}
                </p>
              </div>
            </div>
          </div>
          <div className="TrainingChildCardInput">
            <TrainingChildCard />
          </div>
        </div>
        <div className="tutor-profile-buttom-edit">
          <ButtonEditTutorProfile tutorId={tutor._id} />{" "}
          {/* Pasar tutorId aquí */}
        </div>
      </div>
    </div>
  );
};

export default ProfileTutorCard;
