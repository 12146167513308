import React, { useState } from "react";
import "../../css/exercise/exerciseCop01.css";

export const ComponentOne = ({ setScore }) => {
  const alphabet = "ABCDEF";
  const randomAlphabets = alphabet
    .split("")
    .sort(() => 0.5 - Math.random())
    .slice(0, 3);

  const [isCorrect, setIsCorrect] = useState(null);
  const [feedback, setFeedback] = useState("");

  const speakText = (text) => {
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.lang = "en-GB";
    window.speechSynthesis.speak(utterance);
  };

  const handleLetterClick = (letter) => {
    if (letter === randomAlphabets[0]) {
      setIsCorrect(true);
      setFeedback("Hurrah!");
      setScore(1);
    } else {
      setIsCorrect(false);
      setFeedback("Whoops!");
      setScore(0);
    }
  };

  return (
    <div className="exercise-one">
      <h2>Live Tutoring Assessment</h2>
      <p className="instructions">
        <strong>Instructions for Parents:</strong> Please make sure your child
        completes the test on their own, without assistance. The test is best
        experienced on a touch-screen device like a tablet or iPad.
      </p>

      <div className="question">
        <p>Can you select the letter '{randomAlphabets[0]}'?</p>
        <button
          className="audio-btn"
          onClick={() =>
            speakText(`Can you select the letter ${randomAlphabets[0]}?`)
          }
        >
          <span role="img" aria-label="speaker">🔊</span>
        </button>
      </div>

      <div
        className={`rotDiv ${
          isCorrect === true
            ? "correct"
            : isCorrect === false
            ? "incorrect"
            : ""
        }`}
      >
        {isCorrect !== null && (
          <span className="feedback-text">{feedback}</span>
        )}
        {isCorrect === null &&
          alphabet.split("").map((letter, index) => (
            <span
              key={index}
              className="spread-letter"
              onClick={() => handleLetterClick(letter)}
              style={{
                position: "absolute",
                top: `${Math.random() * 80}%`,
                left: `${Math.random() * 80}%`,
                // transform: `rotate(${Math.random() * 360}deg)`,
              }}
            >
              {letter}
            </span>
          ))}
      </div>
    </div>
  );
};
