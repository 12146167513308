import React from "react";
import Navbar from "../components/NavBar";
import Footer from "../components/HomeFooter";


const DisabilityTestResult = () => {
    return ( 
        <div>
           <header>
        <Navbar />
      </header>

    <section className="assessment">
      <h2>Complete Child Learning Skills Assessment</h2>
      <div class="assessment-content">
            <div className="message-box">
                <p>Great job! Keep going! You're doing amazing!</p>
            </div>
            <button className="next-btn">Next</button>
      </div>
    </section>
            <Footer></Footer>
        </div>
     );
}
 
export default DisabilityTestResult;