// src/pages/TutorList.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/NavBar";
import HomeFooter from "../components/HomeFooter";
import TutorCard from "../components/TutorCard"; // Importar el nuevo componente
import "../css/TutorList.css";

const TutorList = () => {
  const navigate = useNavigate();
  const [tutors, setTutors] = useState([]);

  // Obtener los datos de los tutores desde la base de datos
  useEffect(() => {
    const fetchTutors = async () => {
      try {
        const response = await fetch("http://localhost:5001/api/tutors"); // Asegúrate de que esta ruta sea correcta
        const data = await response.json();
        setTutors(data);
      } catch (error) {
        console.error("Error fetching tutors:", error);
      }
    };

    fetchTutors();
  }, []);

  // Función para redirigir al perfil del tutor
  const handleSelectTutor = (id) => {
    // if (localStorage.getItem("userType") === "parent") {
      navigate(`/tutor-profile-public/${id}`);
    // } 
    // else {
    //   navigate(`/tutor-profile/${id}`); // Redirige al perfil del tutor seleccionado
    // }
  };

  return (
    <div className="page-container">
      <header>
        <Navbar />
      </header>
      <main className="maincontentWrapperTutorList">
        <div className="tutor-list-cards">
          <div className="tutor-list-container">
            <h2>Select Tutor</h2>
            <div className="tutor-grid">
              {tutors.length > 0 ? (
                tutors.map((tutor) => (
                  <TutorCard
                    key={tutor._id}
                    tutor={tutor}
                    handleSelectTutor={handleSelectTutor}
                  />
                ))
              ) : (
                <p>No tutors available at the moment.</p>
              )}
            </div>
          </div>
        </div>
      </main>
      <footer>
        <HomeFooter />
      </footer>
    </div>
  );
};

export default TutorList;
