import React, { useEffect, useState } from "react";
import "../css/ParentProfileCard.css";
import ButtonChildProfile from "./Button/ButtonChildProfile";
import { useNavigate } from "react-router-dom"; // For navigation

// Dummy Image imports
import imgVictor from "../assets/images/Parent1.svg"; // Parent image (will use the same for now)
import axios from "axios"; // To fetch parent data from backend

export default function ParentProfileCard() {
  const [parentData, setParentData] = useState(null); // To store parent data fetched from backend
  const [children, setChildren] = useState([]); // To store children array

  const navigate = useNavigate(); // For navigation

  useEffect(() => {
    const fetchParentData = async () => {
      const uid = localStorage.getItem("user_uid"); // Get the UID from localStorage
      try {
        const response = await axios.get(
          `http://localhost:5001/api/auth/parent/parentData/${uid}`
        );
        setParentData(response.data); // Set the parent data
        setChildren(response.data.children); // Set the children array
      } catch (err) {
        console.error("Error fetching parent data:", err);
        navigate("/"); // Navigate to an error page if fetching fails
      }
    };

    fetchParentData(); // Fetch parent data when the component mounts
  }, [navigate]);

  if (!parentData) {
    return <div>Loading...</div>; // Show loading while fetching data from DB
  }

  return (
    <div className="parent-list-tutor-maxwidth">
      <div className="parent-list-container">
        <h2 className="parent-Montserrat">Parent Profile</h2>
        <div className="parent-list-profile-card">
          <div className="parent-list-profile-tutor-details">
            <img
              src={imgVictor}
              alt="Parent"
              className="parent-list-profile-image"
            />
            <h3 className="parent-Montserrat">
              {parentData.fname} {parentData.lname}
            </h3>
            <p className="parent-profile-aboutme">
              {parentData.parentDescription}
            </p>
          </div>
          <div className="parent-list-children">
            {children.length > 0 ? (
              children.map((child, index) => (
                <div key={index} className="parent-description-tutor">
                  <img
                    src={child.childImage || imgVictor}
                    alt={`Child ${index + 1}`}
                  />
                  <h3>{child.childName}</h3>
                  <ButtonChildProfile child={child} />
                </div>
              ))
            ) : (
              <p>No children data available.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
