import React from 'react';
import '../Button/Button.css';
import { useNavigate } from 'react-router-dom';

export default function ButtonFindTutor() {
    const navigate = useNavigate();

    const handleClickFindTutor = () => {
        navigate('/live-tutoring'); // Update with your actual route path
    };

    return (
        <div className="buttonFindTutor"> 
            <button onClick={handleClickFindTutor}>Find a tutor</button>
        </div>
    );
}