import React from 'react';
import '../Button/Button.css'
import { useNavigate } from "react-router-dom";

export default function ButtonFreeTest188() {
    const navigate = useNavigate();

    const handleClick = () => {
      navigate("/disability-landing"); // navigate to the ExerciseView page
    };
  
    return (
        <div className="buttonFreeTest188"> 
            <button onClick={handleClick}>Free test</button>
        </div>
    );
}