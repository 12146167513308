import { useNavigate } from "react-router-dom";
import HomeFooter from "../components/HomeFooter";
import Navbar from "../components/NavBar";
import { ComponentOne } from "../components/exercise/exerciseCop01";
import { ComponentTwo } from "../components/exercise/exerciseCop02";
import { ComponentThree } from "../components/exercise/exerciseCop03";
import { ComponentFour } from "../components/exercise/exerciseCop04";
import { ComponentFive } from "../components/exercise/exerciseCop05";
import { ComponentSix } from "../components/exercise/exerciseCop06";
import { ComponentSeven } from "../components/exercise/exerciseCop07";
import { ComponentEight } from "../components/exercise/exerciseCop08";
import ComponentNine from "../components/exercise/exerciseCop09";
import "../css/ExerciseView.css";
import React, { useState, useEffect, useCallback } from "react";

const ExerciseView = () => {
  const [currentComponent, setCurrentComponent] = useState(0);
  const [micAllowed, setMicAllowed] = useState(false);
  const [micPermissionDenied, setMicPermissionDenied] = useState(false);
  const [scores, setScores] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [result, setResult] = useState(null);
  const navigate = useNavigate();

  // Wrap updateScore in useCallback to make it stable
  const updateScore = useCallback((index, value) => {
    setScores((prevScores) => {
      const newScores = [...prevScores];
      newScores[index] = value;
      return newScores;
    });
  }, []);

  console.log(result);
  

  const components = [
    <ComponentOne key="1" setScore={(value) => updateScore(0, value)} />,
    <ComponentTwo key="2" setScore={(value) => updateScore(1, value)} />,
    <ComponentThree key="3" setScore={(value) => updateScore(2, value)} />,
    <ComponentFour key="4" setScore={(value) => updateScore(3, value)} />,
    <ComponentFive key="5" setScore={(value) => updateScore(4, value)} />,
    <ComponentSix key="6" setScore={(value) => updateScore(5, value)} />,
    <ComponentSeven key="7" setScore={(value) => updateScore(6, value)} />,
    <ComponentEight key="8" setScore={(value) => updateScore(7, value)} />,
    <ComponentNine key="9" setScore={(value) => updateScore(8, value)} />,
  ];

  const handleNext = () => {
    if (currentComponent < components.length - 1) {
      setCurrentComponent((prevComponent) => prevComponent + 1);
    } else {
      calculateResult();
      const userUID = localStorage.getItem("user_uid");
      if (userUID !== null && userUID !== "") {
        navigate("/parent-profile");
      } else {
        navigate("/parentSign");
      }
    }
  };

  const calculateResult = () => {
    const totalScore = scores.reduce((sum, score) => sum + score, 0);
    setResult(`Your final score is: ${totalScore}/${scores.length}`);
  };

  useEffect(() => {
    const checkMicPermission = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({ audio: true });
        setMicAllowed(true);
      } catch (error) {
        console.log("Microphone access denied");
        setMicPermissionDenied(true);
      }
    };

    checkMicPermission();
  }, []);

  const closeModal = () => {
    setMicPermissionDenied(false);
  };

  const progressPercentage = ((currentComponent + 1) / components.length) * 100;

  return (
    <div className="ExerciseViewGradient">
      <header>
        <Navbar />
      </header>
      <main>
        <div className="cwSentence">
          {micAllowed ? (
            <div>
              {components[currentComponent]}
              <div className="optionList">
                <button onClick={handleNext}>Next</button>
              </div>
              <div className="progress-bar-container1">
                <div
                  className="progress-bar-fill1"
                  style={{ width: `${progressPercentage}%` }}
                ></div>
              </div>
              <p className="step-count">
                Step {currentComponent + 1} out of {components.length}
              </p>
              {/* {result && <p>{result}</p>} */}
            </div>
          ) : (
            <div>
              <p>
                Please enable microphone access to continue with the exercises.
              </p>
            </div>
          )}
        </div>
      </main>

      {micPermissionDenied && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <h2>Microphone Access Denied</h2>
            <p>
              Please enable microphone access in your browser settings to
              continue.
            </p>
          </div>
        </div>
      )}

      <footer>
        <HomeFooter />
      </footer>
    </div>
  );
};

export default ExerciseView;
