import React from "react";
import "../css/StudentProfileCard.css";
import StudentsListProgressCard from "../components/StudentsListProgressCard";
import StudentProfileDetailsCard from "../components/StudentProfileDetailsCard";
import { useLocation } from "react-router-dom";

export default function StudentProfilePublicCard() {
  const location = useLocation();
  const { child } = location.state || {};
  return (
    <div className="students-profile-maxwidth">
      <div className="students-profile-container">
        <h2 className="student-Montserrat">Student Profile</h2>
        <div className="student-profile-card">
          <div>
            <StudentProfileDetailsCard child={child} />
          </div>
          <div>
            <StudentsListProgressCard child={child} />
          </div>
        </div>
      </div>
    </div>
  );
}
