import '../css/HomeFooter.css';
import React from 'react';
import imgLogo from '../assets/images/logo_updated_white.svg';

export default function HomeFooter() {
    return (
        <div className="HomeFooter">
            <p>Terms and Conditions</p>
            <img src={imgLogo} alt="Logo"/>
            <small>© 2024 LearnEZ. All rights reserved</small>
        </div>
    );
}