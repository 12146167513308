// ComponentEight.js
import React, { useState, useRef, useEffect } from "react";
import "../../css/exercise/exerciseCop08.css"; // Link to your CSS file

export const ComponentEight = ({ setScore }) => {
  const [inputValue, setInputValue] = useState("");
  const [feedback, setFeedback] = useState(null);
  const inputRef = useRef(null); // Create a ref for the input field

  useEffect(() => {
    inputRef.current.focus(); // Automatically focus the input field on mount
  }, []);

  const handleChange = (event) => {
    const value = event.target.value;

    // Allow only one-time input
    if (feedback) return; // If feedback already exists, do not allow further changes

    setInputValue(value);

    // Check input value immediately
    if (value === "3") {
      setFeedback("Good job!");
      setScore(1);
    } else if (value.length > 0) {
      // Check only if there's some input
      setFeedback("Sorry, the number didn't match.");
    } else {
      setFeedback(null); // Clear feedback if input is empty
    }
  };

  return (
    <div className="component-eight">
      <h3>
        1 2
        <input
          ref={inputRef} // Assign the ref to the input field
          type="text"
          value={inputValue}
          onChange={handleChange}
          placeholder="_" // Placeholder text
          disabled={feedback !== null} // Disable input after first submission
          className={
            feedback ? (inputValue === "3" ? "correct" : "incorrect") : ""
          } // Add class based on feedback
        />
        4
      </h3>
      {feedback && (
        <p
          className={`feedback ${inputValue === "3" ? "correct" : "incorrect"}`}
        >
          {feedback}
        </p>
      )}
    </div>
  );
};
