import React from 'react';
import '../css/HomeParentCard.css'
import ButtonFindTutor from './Button/ButtonFindTutor';

export default function HomeParentCard() {
    return (
        <div className="HomeParentCard">
            <h1>Parent, looking for <span className="highlight">personalized exercises?</span></h1>
            <p>Tailored exercises can incorporate the child’s interests and strengths, making learning more enjoyable and engaging, enhancing the understanding by focusing on individual challenges and helping children to grasp difficult concepts more effectively.  </p>
            <p>You can consult the tutors available for personalized monitoring of your child's needs, with exercises adapted to each need and profile that will help trace the progress of each step followed.</p>
            <ButtonFindTutor />
        </div>
    );
}

