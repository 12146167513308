import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../services/firebase';
import "../css/ParentLogin.css";          //Same style as ParentLogin.js page
import Navbar from "../components/NavBar";
import HomeFooter from "../components/HomeFooter";

const TutorLogin = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Step 1: Authenticate the user with Firebase
      const userCredential = await signInWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );
      const firebaseUser = userCredential.user;
  
      // Paso 1: Intentar buscar el tutor por UID en la base de datos
      let response = await fetch(`http://localhost:5001/api/tutors/firebase/${firebaseUser.uid}`);
      let data = await response.json();
  
      // Paso 2: Si el tutor no se encuentra por UID, busca por email
      if (!response.ok) {
        response = await fetch(`http://localhost:5001/api/tutors/email/${firebaseUser.email}`);
        data = await response.json();
  
        // Si el tutor se encuentra por email, actualizar el UID
        if (response.ok && data._id) {
          await fetch(`http://localhost:5001/api/tutors/updateUid/${data._id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ firebaseUid: firebaseUser.uid }),
          });
        } else {
          throw new Error('No se pudo obtener el ID del tutor');
        }
      }
  
      // Guardar el token en localStorage y redirigir al perfil del tutor
      localStorage.setItem('firebaseToken', await firebaseUser.getIdToken());
      navigate(`/tutor-profile/${data._id}`);
    } catch (error) {
      console.error("Error logging in:", error);
      setErrorMessage("Invalid email or password. Please try again.");
    }
  };

  return (
    <div>
      <header>
        <Navbar />
      </header>
      <main  className="maincontentWrapperPLogin">
        <div className="contentWrapperPLogin">
          <h1>Tutor Login</h1>
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
          <div className="Parentlogin-container">
            <form className="login-form" onSubmit={handleSubmit}>
              <p>
                <strong>Welcome to LearnEZ!</strong> <br /> Please log in using your email and
                password to access your parent dashboard.
              </p>
              <div className="input-group">
                <label>Email:</label>
                <input
                  type="email"
                  name="email"
                  placeholder="Enter your email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="input-group">
                <label>Password:</label>
                <input
                  type="password"
                  name="password"
                  placeholder="Enter your password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
              </div>              
              <button type="submit" className="login-btn">Login in to <span>LearnEZ</span></button>
            </form>
            
                        {/*  <div className="forgot-password">
                <button type="button">Forgot password?</button>
              </div>
              
              <div className="register">
                <p>Not a member yet? <Link to="/tutorRegister">Join now</Link></p>
              </div> */}
              <Link to="/tutorRegister" className="forgot-password-btn">
              <button className="forgot-password-btn">
                Create an account!
              </button>
              </Link>

            </div>
            <p className="termsServiceParentlogin">
            Terms of Service and Privacy Policy <br />
            Copyright © Mind InFormation Inc. 2024 - All Rights Reserved
          </p>
        </div>
      </main>
      <footer>
        <HomeFooter />
      </footer>
    </div>  
  );
};

export default TutorLogin;
