import React from "react";


const ZoomWaitingRoom = () => {
    return ( 
        <>
    <section className="zoom-main">
        <div class="zoom-pop">
            <p><strong>Please wait for the host to start this meeting.</strong></p>
            <p>John Doe's personal meeting room</p>
            <button>Test speaker and microphone</button>
        </div>
      </section>
        </>
     );
}
 
export default ZoomWaitingRoom;