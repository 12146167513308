import React from "react";
import Navbar from "../components/NavBar";
import Footer from "../components/HomeFooter";


const DisabilityWeeklyAssessmentResult = () => {
  return (
    <>
      <header>
        <Navbar />
      </header>
      <section class="assessment">
        <h2>Complete Child Learning Skills Assessment</h2>
      </section>
      <div className="assessment-box">
        <p className="test-completed">
          <strong>Test Completed!</strong> See Your Child's Progress
        </p>
        <div className="results-box">
          <p>Here's how you did:</p>

          <div className="result">
            <h3>Reading Results</h3>
            <p>You show a low risk for reading challenges</p>
            <div className="risk-bar">
              <div className="bar">
                <div className="filled-bar reading-bar" style={{ width: "40%", backgroundColor:"red"}}></div>
              </div>
              <p className="percent">40%</p>
            </div>
            <div className="labels">
              <span>Low Risk</span>
              <span>High Risk</span>
            </div>
          </div>

          <div className="result ">
            <h3>Writing Results</h3>
            <p>You show a possible risk for reading challenges</p>
            <div className="risk-bar">
              <div className="bar">
                <div className="filled-bar writing-bar" style={{ width: "60%"}}></div>
              </div>
              <p className="percent">60%</p>
            </div>
            <div className="labels">
              <span>Low Risk</span>
              <span>High Risk</span>
            </div>
          </div>
        </div>
        <button className="complete-btn">Complete </button>

      </div>
      <Footer />
    </>
  );
};

export default DisabilityWeeklyAssessmentResult;
