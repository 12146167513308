import React from "react";
import "../css/Purpose.css";
import imgGirlHero1 from "../assets/images/girl_hero_nobackg 1.svg";
import ButtonFreeTest from "./Button/ButtonFreeTest";
import ParentSignUp from "./Button/ParentSignUp";
import TutorSignUp from "./Button/TutorSignUp";

export default function Purpose() {
  return (
    <div className="purpose">
      <div className="maxWidthPurpose">
        <h1>Simple Steps to Better Learning</h1>

        <p>
          Our purpose is to assists parents and families in identifying
          potential learning disabilities such as dyslexia, ADHD, dysgraphia,
          and dyscalculia and provides specialized support to help children
          overcome challenges.
        </p>
        <p>
          Here, you can find comprehensive and accessible tools to early
          detection and consistent support, such as live tutoting and tailored
          exercises.
        </p>

        <img src={imgGirlHero1} alt="Girl1" />

        <p className="purposeLastP">
          Come with us to empower your children, support their development and
          boost their confidence to go even further.
        </p>

        <div className="purposeButtons">
          <ButtonFreeTest />
          <ParentSignUp />
          <TutorSignUp />
        </div>
      </div>
    </div>
  );
}
