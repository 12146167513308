import React from 'react';
import '../css/StudentsListCard.css'
import ButtonChildProfile from './Button/ButtonChildProfile';
import imgJohn from '../assets/images/John.svg'
import imgRyan from '../assets/images/Ryan.svg' 
import imgMary from '../assets/images/Mary.svg' 

export default function StudentsListCard() {
    return (
    <div className='students-list-tutor-maxwidth'>    
    <div className="students-list-container">
        <h2 className='tutor-Montserrat'>Student List</h2>
        <div className="students-list-profile-card">
            <div className='students-list-profile-tutor-details'>
                <img
                    src={imgJohn} 
                    alt="Tutor"
                    className="students-list-profile-image"
                />
                <h3 className='tutor-Montserrat'>John Doe</h3>
                <p className='students-list-tutor-profile-aboutme'>As a special education tutor with 3 years of experience, I offer personalized support, adapting lessons to each student's needs and goals. My goal as a personal tutor is to help them to grow academically and build more confidence in a supportive environment.</p>
            </div>
            <div className='students-list-tutor'>
                <div className='students-description-tutor'>
                    <img src={imgRyan} alt="Boy"/>
                    <h3>Ryan</h3>
                    <ButtonChildProfile />
                </div>
                <div className='students-description-tutor'>
                    <img src={imgMary} alt="Girl"/>
                    <h3>Mary</h3>
                    <ButtonChildProfile />
                </div>
            </div>
        </div>
    </div>
    </div>

    );
}