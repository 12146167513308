import React from 'react';
import Navbar from "../components/NavBar";
import StudentsListCard from "../components/StudentsListCard";
import HomeFooter from "../components/HomeFooter";
import '../css/StudentsList.css'; 

const StudentsList = () => {
  return (
    <div className='Students-List-Page'>
      <header>
        <Navbar />
      </header>
      <main>
        <StudentsListCard />

      </main>
      <footer>
        <HomeFooter /> 
      </footer>
    </div>
  );
};

export default StudentsList;