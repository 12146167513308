import React from "react";
import "../css/StudentProfileCard.css";
import imgRyan from "../assets/images/Ryan.svg";

export default function StudentProfileDetailsCard({ child }) {
  return (
    <div className="student-profile-details">
      <img src={imgRyan} alt="Tutor" className="student-profile-image" />
      <h3 className="student-Montserrat">
        {child?.childName || "Name Not found!"}
      </h3>
      <p className="student-profile-aboutme">
        {child?.childDescription || "Data Not found!"}
      </p>
    </div>
  );
}
