import React from "react";
import "../css/StudentProfileCard.css";

export default function StudentsListProgressCard({ child }) {
  const progressPercentage = ""
  return (
    <div className="students-list-progress">
      <div className="students-progress">
        <h3 className="student-Montserrat">Disability Test Result</h3>
        <h4>Reading:</h4>
        <div className="progress-bar-container">
                <div
                  className="progress-bar-fill"
                  style={{ width: `${progressPercentage}%` }}
                ></div>

        </div>
        <h4>Writing:</h4>
        <div className="progress-bar-container">
                <div
                  className="progress-bar-fill"
                  style={{ width: `${progressPercentage}%` }}
                ></div>

        </div>
        {/* <p>{child.result}%</p> */}
      </div>
      <div className="students-progress">
        <h3 className="student-Montserrat">Progress</h3>
        <h4>Reading:</h4>
        <div className="progress-bar-container">
                <div
                  className="progress-bar-fill"
                  style={{ width: `${progressPercentage}%` }}
                ></div>

        </div>
        <h4>Writing:</h4>
        <div className="progress-bar-container">
                <div
                  className="progress-bar-fill"
                  style={{ width: `${progressPercentage}%` }}
                ></div>

        </div>
        {/* <p>{child.result}%</p>*/}
      </div>
    </div>
  );
}
