import React from 'react';
import '../css/ProfileTutorCard.css';
import imgJohn from '../assets/images/John.svg';

export default function ProfileTutorDetailsCard({ tutor }) {
    return (
      <div className="profile-tutor-details">
        <img
          src={tutor.profileImage || imgJohn} // Usa la imagen del tutor si está disponible, sino muestra una por defecto
          alt="Tutor"
          className="profile-image"
        />
        <h3 className="tutor-Montserrat">
          {tutor.firstName} {tutor.lastName}
        </h3>
        <div className="tutor-profile-certificate">
          <p className="tutor-profile-certificate-no">Certificate:</p>
          <div className="tutor-profile-certificate-number">
            <p>{tutor.certificateNumber || "N/A"}</p>{" "}
            {/* Muestra el número de certificado o "N/A" si no está disponible */}
          </div>
        </div>
      </div>
    );
}