import React from "react";
import Navbar from "../components/NavBar";
import Footer from "../components/HomeFooter";

const DisabilityWeeklyAssessment = () => {
    return ( 
        <>
       <header>
        <Navbar />
      </header>
        <section class="assessment">
      <h2>Weekly Assessment</h2>
      <p class="instructions"><strong>Instructions for Parents:</strong>  Please make sure your child completes the test on their own, without assistance. The test is best experienced on a touch-screen device like a tablet or iPad.</p>
      <div class="test-question">
        <div class="progress-bar">
          <span>Step 1 of 10</span>
        </div>
        <p>1. Which word is for 'dog'?</p>
        <div class="answers">
          <button>option 1</button>
          <button>option 2</button>
          <button>option 3</button>
          <button>option 4</button>
          <button>option 5</button>
        </div>
        <button class="next-btn">Next</button>
      </div>
      </section>
      
    <Footer/>
        </>
     );
}
 
export default DisabilityWeeklyAssessment;