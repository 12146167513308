import React from "react";
import "../css/ProfileTutorCard.css";

export default function ProfileTutorInfoCard({ tutor }) {
  return (
    <div className="profile-tutor-infoCard">
      <strong className="tutor-Montserrat">Specialization:</strong>
      <p>{tutor.specialization || "N/A"}</p>{" "}
      {/* Muestra la especialización o "N/A" si no está disponible */}
      <strong className="tutor-Montserrat">Relevant Experience:</strong>
      <p>{tutor.experience || "N/A"}</p>{" "}
      {/* Muestra la experiencia o "N/A" si no está disponible */}
    </div>
  );
}
