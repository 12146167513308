import React, { useState } from "react";
import Navbar from "../components/NavBar";
import HomeFooter from "../components/HomeFooter";
import "../css/ParentSignup.css";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../services/firebase";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ParentSignup = () => {
  const navigate = useNavigate();
  const [firstName, setfName] = useState("");
  const [lastName, setlName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [dateOfBirth, setdateOfBirth] = useState("");
  const [parentDescription, setparentDescription] = useState("");
  const [childDescription, setchildDescription] = useState("");
  const [childName, setchildName] = useState("");
  const [childAge, setchildAge] = useState("");
  const [notification, setNotification] = useState("");
  const [profileImage, setProfileImage] = useState(null);
  const [childImage, setChildImage] = useState(null);

  // Handle profile image upload
  const handleProfileImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle child image upload
  const handleChildImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setChildImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const removeProfileImage = () => setProfileImage(null);
  const removeChildImage = () => setChildImage(null);

  const handleSignup = async (e) => {
    // const parentImg = Buffer.from(profileImage.split(",")[1], "base64");
    // const childImg = Buffer.from(childImage.split(",")[1], "base64");

    // console.log("pimg" + { parentImg });
    // console.log("cimg" + { childImg });

    function getRandomValue() {
      return Math.floor(Math.random() * (100 - 50 + 1)) + 50;
    }


    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const uid = userCredential.user.uid;

      console.log({
        fname: firstName,
        lname: lastName,
        email: email,
        password: password,
        phone: phone,
        userType: "parent",
        dateOfBirth: dateOfBirth,
        parentDescription: parentDescription,
        uid: uid,
        children: [
          {
            childName: childName,
            childAge: childAge,
            childDescription: childDescription,
          },
        ],
      });
      // result: result !== null ? result : "",
      const resultValue = getRandomValue();
      // Send UID, additional data, and images to your backend
      const response = await axios.post(
        "http://localhost:5001/api/auth/parent/signup",
        {
          fname: firstName,
          lname: lastName,
          email: email,
          password: password,
          phone: phone,
          userType: "parent",
          dateOfBirth: dateOfBirth,
          parentDescription: parentDescription,
          uid: uid,
          children: [
            {
              childName: childName,
              childAge: childAge,
              childDescription: childDescription,
              result: resultValue,
            },
          ],
        }
      );

      console.log("Signup successful:", response.data);
      const { token } = response.data;
      localStorage.setItem("token", token);
      localStorage.setItem("user_uid", uid);
      localStorage.setItem("userType", "parent");

      setNotification("Sign-up successful!");
      setTimeout(() => {
        setNotification("");
        navigate("/parent-profile");
      }, 2000);
    } catch (err) {
      alert("Sign-up failed");
      console.error(err);
    }
  };

  return (
    <div>
      <header>
        <Navbar />
      </header>
      <main className="parent-signup-gradient">
        <h1>Sign up</h1>
        <div className="contentWrapper">
          <div className="parent-signup-provide-info">
            <p>
              Please provide the following information so we can calculate your
              child's results and email the report to you.
            </p>
          </div>
          <div className="ParentSign-YourInfo">
            <h2>Your Info</h2>
            <form onSubmit={handleSignup} className="yourinfoformparent">
              <div className="input-g yourName">
                <div className="divstacksignup">
                  <label>First Name:</label>
                  <input
                    type="text"
                    value={firstName}
                    onChange={(e) => setfName(e.target.value)}
                  />
                </div>
                <div className="divstacksignup">
                  <label>Last Name:</label>
                  <input
                    type="text"
                    value={lastName}
                    onChange={(e) => setlName(e.target.value)}
                  />
                </div>
              </div>
              <div className="input-g email divstacksignup inputlargesize">
                <label>Your Email:</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="input-g password divstacksignup inputlargesize">
                <label>Password:</label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="input-g phone divstacksignup inputlargesize">
                <label>Phone:</label>
                <input
                  type="number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div className="input-g age-gender divstacksignup inputlargesize">
                <label>Parent Description:</label>
                <input
                  type="text"
                  value={parentDescription}
                  onChange={(e) => setparentDescription(e.target.value)}
                />
              </div>
              <div className="input-g ge-gender divstacksignup inputlargesize">
                <label>Profile Image:</label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleProfileImageUpload}
                />
                {profileImage && (
                  <>
                    <img
                      src={profileImage}
                      alt="Profile preview"
                      className="image-preview"
                    />
                    <button type="button" onClick={removeProfileImage}>
                      Remove
                    </button>
                  </>
                )}
              </div>
            </form>
          </div>

          <div className="ParentSign-StudentInfo">
            <h2>Student Info</h2>
            <form className="studentinfoformparent">
              <div className="input-g childName divstacksignup inputlargesize">
                <label>Child's Name:</label>
                <input
                  type="text"
                  value={childName}
                  onChange={(e) => setchildName(e.target.value)}
                />
              </div>
              <div className="input-g age-gender">
                <div className="divstacksignup">
                  <label>Child's Age:</label>
                  <input
                    type="text"
                    value={childAge}
                    onChange={(e) => setchildAge(e.target.value)}
                  />
                </div>
                <div className="divstacksignup">
                  <label>Child Description:</label>
                  <input
                    type="text"
                    value={childDescription}
                    onChange={(e) => setchildDescription(e.target.value)}
                  />
                </div>
              </div>
              <div className="input-g age-gender">
                <div className="divstacksignup">
                  <label>Date of Birth:</label>
                  <input
                    type="date"
                    placeholder="YYYY-MM-DD"
                    value={dateOfBirth}
                    onChange={(e) => setdateOfBirth(e.target.value)}
                  />
                </div>
                <div className="divstacksignup">
                  <label>Child Image:</label>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleChildImageUpload}
                  />
                  {childImage && (
                    <>
                      <img
                        src={childImage}
                        alt="Child preview"
                        className="image-preview"
                      />
                      <button type="button" onClick={removeChildImage}>
                        Remove
                      </button>
                    </>
                  )}
                </div>
              </div>
            </form>
          </div>

          <div className="parentsignupButtonSave">
            <button type="submit" onClick={handleSignup}>
              Save
            </button>
          </div>

          {notification && <div className="notification">{notification}</div>}
        </div>
        <div className="termsandconditions-parentsignup">
          <p>
            Terms of Service and Privacy Policy Copyright © Mind InFormation
            Inc. 2024 - All Rights Reserved
          </p>
        </div>
      </main>
      <footer>
        <HomeFooter />
      </footer>
    </div>
  );
};

export default ParentSignup;
