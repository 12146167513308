import React from "react";
import "../css/TrainingChildCard.css";
import imgRyan from "../assets/images/Ryan.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";

export default function TrainingChildCard() {
  return (
    <div className="TrainingChildCard">
      <div className="TrainingChildCard1">
        <h4 className="tutor-Montserrat">Training with</h4>
        <p>Ryan</p>
      </div>
      <div className="TrainingChildCard2">
        <img src={imgRyan} alt="Boy" />
        <div className="TrainingChildCard3">
          <div className="TrainingChildCard5">
            <p>Wed</p>
            <p className="TrainingChildCard5P">28</p>
          </div>
          <div className="TrainingChildCard4">
            <FontAwesomeIcon icon={faClock} />
            <p>9:00am to 9:30am</p>
          </div>
          <div>
            <p>30 mins class Ryan &lt;&gt; John</p>
          </div>
          <div className="TrainingZoomLink">
            <a href="https://www.zoom.com">Zoom Link</a>
          </div>
        </div>
      </div>
    </div>
  );
}
