import React from "react";
import { useNavigate } from "react-router-dom";
import "../Button/Button.css";

export default function ButtonStudentsList() {
  const navigate = useNavigate();

  const handleClickStudentsList = () => {
    navigate("/students-list"); // Update with your actual route path
  };

  return (
    <div className="ButtonStudentsList">
      <button onClick={handleClickStudentsList}>Students List</button>
    </div>
  );
}
