import React from 'react';
import '../css/HomeTutorCard.css'
import ButtonCreateProfile from './Button/ButtonCreateProfile';


export default function HomeTutorCard() {
    return (
        <div className="HomeTutorCard">
            <h1><span className="highlight">Tutors,</span> join us and make the difference</h1>
            <p>Are you passionate about making a difference in the lives of students with learning disabilities? So come with us to make a meaningful impact and empower children to overcome challenges, working according to your availability.</p>
            <p>Create your account and join us in our purpose to provide personalized support to help these students thrive academically and personally.</p>
            <ButtonCreateProfile />
        </div>
    );
}


