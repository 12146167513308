import React from 'react';
import Navbar from '../components/NavBar';
import HomeFooter from '../components/HomeFooter';
import '../css/TestResult.css'; 

const TestResult = () => {

  const progressPercentage = ""

  return (
    <div className='page-container-test-result'>
      <header>
        <Navbar />
      </header>
      <main className='maincontentWrapper-test-result'>
        <h1>Complete Child Learning Skills Assessment Result</h1>
        <div className='test-result-card'>
            <h2>Your Results Are Ready!</h2>
            <div className='test-result-card2'>
                <p className='Heres-how-you-did'>Here’s how you did:</p>
                <div className='test-result-data'>
                    <div className='test-results-bars'>
                        <h3>Reading Results</h3>
                        <p>You show a low risk for reading challenges</p>
                        <div className="progress-bar-wrapper">
                            <div className="progress-bar-labels">
                                <span className="label-left">Low Risk</span>
                                <span className="label-right">High Risk</span>
                            </div>
                            <div className="progress-bar-container">
                                <div
                                className="progress-bar-fill"
                                style={{ width: `${progressPercentage}%` }}
                                ></div>
                            </div>
                        </div>
                    </div>
                    <div className='test-results-bars'>
                        <h3>Writing Results</h3>
                        <p>You show a possible risk for writing challenges</p>
                        <div className="progress-bar-wrapper">
                            <div className="progress-bar-labels">
                                <span className="label-left">Low Risk</span>
                                <span className="label-right">High Risk</span>
                            </div>
                            <div className="progress-bar-container">
                                <div
                                className="progress-bar-fill"
                                style={{ width: `${progressPercentage}%` }}
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='test-result-buttons'>
                    <button type="button">Download  Report</button>
                    <button type="button">Email  Report</button>
                    <button type="button">Try Another Test</button>
                    <button type="button">Register for Live Tutoring</button>
                </div>
            </div>
        </div>
      </main>
      <footer>
        <HomeFooter />
      </footer>
    </div>
  );
};

export default TestResult;