import React, { useState, useEffect } from "react";
import "../../css/exercise/exerciseCop01.css"; // Update the CSS file path as necessary

export const ComponentTwo = ({ setScore }) => {
  const numbers = "123456"; // Define your numbers
  const randomNumbers = numbers
    .split("")
    .sort(() => 0.5 - Math.random())
    .slice(0, 3);

  const [currentStep, setCurrentStep] = useState(0); // Tracks the current step
  const [correctSelections, setCorrectSelections] = useState([]); // Tracks the correct selections
  const [completed, setCompleted] = useState(false); // Tracks if the task is completed
  const [isCorrect, setIsCorrect] = useState(null); // Tracks if the last selection was correct
  const [feedback, setFeedback] = useState(""); // Holds feedback text

  // Function to speak the current question
  const speakText = (text) => {
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.lang = "en-GB"; // Set language to English
    window.speechSynthesis.speak(utterance);
  };

  // Speak the current number when the currentStep changes
  useEffect(() => {
    if (currentStep < randomNumbers.length) {
      speakText(`Please select the number ${randomNumbers[currentStep]}`);
    }
  }, [currentStep, randomNumbers]);

  // Handle number selection
  const handleNumberClick = (number) => {
    if (number === randomNumbers[currentStep]) {
      setCorrectSelections([...correctSelections, number]); // Store correct selection
      setIsCorrect(true); // Mark as correct
      setFeedback("Hurrah!"); // Set feedback
      setScore(1);

      // Move to the next step
      setCurrentStep(currentStep + 1);

      // Check if the task is completed
      if (currentStep + 1 === randomNumbers.length) {
        setCompleted(true); // Set completion status to true
      }
    } else {
      setIsCorrect(false); // Mark as incorrect
      setFeedback("Whoops!"); // Set feedback
      setScore(0);
      speakText(
        `Wrong number. Please select the number ${randomNumbers[currentStep]}`
      );
    }
  };

  // Helper functions for positioning and rotation
  const getRandomRotation = () =>
    `rotate(${Math.floor(Math.random() * 360)}deg)`;
  const getRandomPosition = () => ({
    top: `${Math.floor(Math.random() * 90)}%`,
    left: `${Math.floor(Math.random() * 90)}%`,
  });

  return (
    <div className="exercise-two">
      {completed ? (
        <div className="success-message">
          <h2>Completed Successfully!</h2>
        </div>
      ) : (
        <>
          <div className="question">
            <h3>
              Please select the following number: {randomNumbers[currentStep]}
            </h3>
          </div>
          <div
            className={`rotDiv ${
              isCorrect === true
                ? "correct"
                : isCorrect === false
                ? "incorrect"
                : ""
            }`}
          >
            {isCorrect === null ? (
              numbers.split("").map((number, index) => {
                const randomPosition = getRandomPosition();
                return (
                  <span
                    className="number-select"
                    key={index}
                    onClick={() => handleNumberClick(number)}
                    style={{
                      top: randomPosition.top,
                      left: randomPosition.left,
                      position: "absolute",
                      // transform: getRandomRotation(),
                    }}
                  >
                    {number}
                  </span>
                );
              })
            ) : (
              <span className="feedback-text">{feedback}</span>
            )}
          </div>
          <div className="selText">
            <h3>Selected Numbers:</h3>
            <p>
              {correctSelections.length === 3
                ? "All selections correct!"
                : "Keep selecting."}
            </p>
          </div>
        </>
      )}
    </div>
  );
};
