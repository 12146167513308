import React from "react";
import AboutUsImg from "../assets/images/SVG_Files/Superhero-girl3.svg";

const AboutUsHero = () => {
  return (
    <section className="AboutUsHero">
      <div className="AboutUsHeroImage">
        <img src={AboutUsImg} alt="About Us Hero" />
      </div>
      <div className="AboutUsHeroText">
        {" "}
        {/* Updated class to className */}
        <h1>One team, one project.</h1>
        <p className="AboutUsHero-description">
          We are a team of nine students of web design and development that
          believe that even small acts can contribute to a better community and
          a hopeful future. This project was a result of this wish, with all
          team members connected to deliver an integrated solution to help
          parents of children with learning disabilities.
        </p>
        <p>
          Using a friendly web-based learning platform, parents who want to
          efficiently identify potential learning challenges can find
          easy-to-use diagnostic tests and tailored exercises for their
          children. We also offer engaging activities and access to certified
          tutoring professionals, creating an outcome-focused solution that
          complements traditional schooling and provides more self-confidence to
          build an even brighter future.
        </p>
        <p className="AboutUsHero-tag">Simple Steps to Better Learning.</p>
      </div>
    </section>
  );
};

export default AboutUsHero;
