import React from 'react';
import Navbar from "../components/NavBar";
import StudentProfileCard from "../components/StudentProfileCard";
import HomeFooter from "../components/HomeFooter";
import '../css/StudentProfile.css'; 

const StudentProfile = () => {
  return (
    <div className='StudentProfilePage'>
      <header>
        <Navbar />
      </header>
      <main>
        <StudentProfileCard />

      </main>
      <footer>
        <HomeFooter /> 
      </footer>
    </div>
  );
};

export default StudentProfile;