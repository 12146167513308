import React from 'react';
import '../Button/Button.css';
import { useNavigate } from 'react-router-dom';

export default function TutorSignUp() {
    const navigate = useNavigate();

    const handleClickTutorSignUp = () => {
        navigate('/tutorRegister'); // Update with your actual route path
    };

    return (
        <div className="tutorSignUp"> 
            <button onClick={handleClickTutorSignUp}>Tutor? Sign up</button>
        </div>
    );
}