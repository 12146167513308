import React from 'react'; // Asegúrate de importar React
import '../css/Discover.css'
import HomeTestCard from "../components/HomeTestCard";
import HomeParentCard from "../components/HomeParentCard";
import HomeTutorCard from "../components/HomeTutorCard";
import imgGirlHero2 from '../assets/images/Superhero-girl 1.svg' 
import imgServices from '../assets/images/Services_art_circle_desk.svg' 

export default function Discover() {
    return (
        <div className="discover"> 
            <div className='maxWidthDiscover'>
                <div className='discoverHeading'>
                    <p className='discoverFirstP'>We can help</p>
                    <div className='discoverSideP'> 
                        <p className='discoverSecP'>your child</p>
                        <p className='discoverThirdP'>to go</p>
                    </div>
                    <p className='discoverForthP'>above and beyond</p>
                </div>

                <img className='discover_imgGirl' src={imgGirlHero2} alt="Girl2"/>
                <img className='discover_imgServices' src={imgServices} alt="Services"/>

                <div className='homeCards'>
                    <HomeTestCard />
                    <HomeParentCard />
                    <HomeTutorCard />
                </div>
            </div> 
        </div>
    );
}
