import '../css/HomeTestCard.css';
import React from 'react';
import ButtonFreeTest188 from './Button/ButtonFreeTest188';

export default function HomeTestCard() {
    return (
        <div className="HomeTestCard">
            <h1><span className="highlight">Test</span> and start empowering your child right now</h1>
            <p>Early testing can unlock full potential by identifying their unique needs and strengths. With timely intervention, you can provide support that enhances their learning experience, increases their confidence, and fosters a positive attitude towards education. </p>
            <p>Empower your child today for a brighter, more successful tomorrow!</p>
            <ButtonFreeTest188 />
        </div>
    );
}

