import React, { useState, useEffect } from "react";
import "../css/ProfileTutorCard.css";
import ButtonTutorConfirm from "./Button/ButtonTutorConfirm";
import ProfileTutorDetailsCard from "../components/ProfileTutorDetailsCard";
import ProfileTutorInfoCard from "../components/ProfileTutorInfoCard";
import TutorProfileAboutmeCard from "../components/TutorProfileAboutmeCard";

import DatePicker from "react-datepicker"; // npm install react-datepicker
import { format } from "date-fns"; // npm install date-fns
import "react-datepicker/dist/react-datepicker.css";

export default function ProfileTutorPublicCard({ id }) {
  const [appointmentDate, setAppointmentDate] = useState(null);
  const [endTime, setEndTime] = useState(null); // End time for the appointment

  const [tutor, setTutor] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!id) {
      console.error("No tutor ID provided.");
      setLoading(false);
      return;
    }

    const fetchTutorDetails = async () => {
      try {
        const response = await fetch(`http://localhost:5001/api/tutors/${id}`);

        if (!response.ok) {
          throw new Error("Failed to fetch tutor data");
        }
        const data = await response.json();
        setTutor(data);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching tutor details:", error);
        setLoading(false);
      }
    };

    fetchTutorDetails();

    // console.log(">>>>>>>>>> " + tutor.availability[0].day);
  }, [id]);

  if (loading) {
    return <p>Loading tutor details...</p>;
  }

  const handleDateChange = (date) => {
    setAppointmentDate(date);
    // Set the end time to one hour after the selected time
    const newEndTime = new Date(date);
    newEndTime.setHours(date.getHours() + 1);
    setEndTime(newEndTime);
  };

  const formattedDate = appointmentDate
    ? `${format(appointmentDate, "yyyy/MM/dd")} - ${format(
        appointmentDate,
        "h:mma"
      )} - ${format(endTime, "h:mma")}`
    : ""; // Format date and time as YYYY/MM/DD - startTime - endTime

  // Check if user_uid exists in localStorage
  const userUid = localStorage.getItem("user_uid");

  return (
    <div className="profile-tutor-maxwidthCard">
      <div className="profile-container">
        <h2 className="tutor-Montserrat">Tutor Profile</h2>
        <div className="profile-card">
          <div className="profile-tutor-details">
            <ProfileTutorDetailsCard tutor={tutor} />
          </div>
          <div className="tutor-profile-buttom-students">
            {/* Conditionally render ButtonTutorConfirm based on user_uid */}
            {userUid && userUid !== "" && (
              <ButtonTutorConfirm
                tutor={tutor}
                selectedDate={appointmentDate}
              />
            )}
          </div>
          <div className="profile-tutor-info">
            <ProfileTutorInfoCard tutor={tutor} />
          </div>
          <div className="tutor-profile-aboutme">
            <TutorProfileAboutmeCard tutor={tutor} />
          </div>
          <div className="profile-tutor-calendar-av">
            <div className="profile-tutor-calendar">
              <label>Select Appointment Date and Time:</label>
              <DatePicker
                selected={appointmentDate}
                onChange={handleDateChange}
                value={formattedDate}
                showTimeSelect
                dateFormat="yyyy/MM/dd h:mm aa"
                placeholder="YYYY/MM/DD - Start Time - End Time"
              />
            </div>
            <div className="profile-tutor-availability">
              <h3>Availability:</h3>

              <div className="profile-tutor-availability-days">
                {Array.isArray(tutor.availability) &&
                tutor.availability.length > 0 ? (
                  tutor.availability.map((slot, index) => (
                    <div key={slot._id || index}>
                      {" "}
                      {/* Use _id if it exists, otherwise use index */}
                      <p>
                        {slot.day} From {slot.start} to {slot.end}
                      </p>
                    </div>
                  ))
                ) : (
                  <p>No time availability</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
