import React from 'react';
import '../css/HomeAboutUs.css'
import ButtonContactUs from './Button/ButtonContactUs'
import imgGirl2 from '../assets/images/Girl1_1.svg'  

export default function HomeAboutUs() {
    return (
        <div className="HomeAboutUs">
            <div className='AboutOrange'>
            <div className='maxWidthAboutUs'>
                <div className="aboutUs">
                    <div className='contactUsHeading'>
                        <p>One Project.</p>
                        <p>One Purpose.</p>
                    </div>
                    <div className='contactUsP'>
                        <p className='aboutUsBold'>We believe in the future.</p>
                        <p>Our web-based learning platform wants to help parents of children with learning disabilities who want to efficiently identify potential learning challenges and carry out tailored solutions by providing easy-to-use diagnostic tests, engaging activities, and offering access to certified tutoring professionals, creating an outcome-focused solution that complements traditional schooling.</p>
                        <p className='aboutUsBold'>We are here for you.</p>                   
                    </div>
                    <img src={imgGirl2} alt="Girl5"/>
                    <div className='contactUsButton'>
                        <ButtonContactUs />
                    </div>
                </div>
            </div>
            </div>
        </div>
    );
}