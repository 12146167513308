import React from 'react';
import '../css/HomeStories.css'
import imgRyan from '../assets/images/Ryan.svg' 
import imgMary from '../assets/images/Mary.svg' 
import imgAnna from '../assets/images/Anna.svg' 
import imgNoah from '../assets/images/Noah.svg'

export default function HomeStories() {
    return (
        <div className="HomeStories">
            <div className='maxWidthStories'>
            <div className='homeHeadTest'>
                <h1>Supporting families and futures. Know their stories.</h1>
                <div className='homeTestimonials'>
                    <article>
                        <img src={imgRyan} alt="Boy1"/>
                        <h2>Ryan</h2>
                        <p>Ryan is a bright and caring child with ADHD, who was struggling to focus in school. With the help of a  tutor and personalized strategies, he discovered his love for science and got motivated to learn.</p>
                    </article>
                    <article>
                        <img src={imgMary} alt="Girl3"/>
                        <h2>Mary</h2>
                        <p>Highly creative and smart, Mary, 5 years old, proved that her dislexya, when correctly treated and with the right support, could be turned into excels in studies and arts at any age.</p>
                    </article>
                    <article>
                        <img src={imgAnna} alt="Girl4"/>
                        <h2>Anna</h2>
                        <p>Anna had difficulty with handwriting and organizing her thoughts on paper due to dysgraphia. With the support of therapy and   assistive exercises, she improved  her writing skills.</p>
                    </article>
                    <article>
                        <img src={imgNoah} alt="Boy2"/>
                        <h2>Noah</h2>
                        <p>Noah found math to be a daunting subject because of his dyscalculia. Through tailored math exercises, he gradually built his number sense and problem-solving skills, building confidence. </p>
                    </article>
                </div>
            </div>
            </div>
        </div>
    );
}