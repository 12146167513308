import React from "react";
import { useNavigate } from "react-router-dom";
import "./Button.css";

const ButtonEditTutorProfile = ({ tutorId }) => {
  const navigate = useNavigate();

  const handleEditClick = () => {
    navigate(`/tutor-edit/${tutorId}`);
  };

  return (
    <button onClick={handleEditClick} className="edit-profile-btn">
      Edit
    </button>
  );
};

export default ButtonEditTutorProfile;
