import React from 'react'; // Asegúrate de importar React
import { NavLink } from "react-router-dom";
import '../css/footer.css';

export default function Footer() {
  return (
    <div className="footerDiv">
      {/*  */}
      <article>
        <h3>Legal</h3>
        <nav>
          <ul>
            <li>
              <NavLink>Privacy Policy</NavLink>
            </li>
            <li>
              <NavLink>Terms and Conditions</NavLink>
            </li>
          </ul>
        </nav>
      </article>
      {/*  */}
      <article>
        <h3>Quicks Links</h3>
        <nav>
          <ul>
            <li>
              <NavLink>Free testing</NavLink>
            </li>
            <li>
              <NavLink>Live Tutoring</NavLink>
            </li>
            <li>
              <NavLink>For tutors</NavLink>
            </li>
            <li>
              <NavLink>About us</NavLink>
            </li>
          </ul>
        </nav>
      </article>
      {/*  */}
      <article>
        <h3>Contact Us</h3>
        <nav>
          <ul>
            <li>
              <NavLink>learnEZ@gmail.com</NavLink>
            </li>
            <li>
              <NavLink>+1 223-554-1234</NavLink>
            </li>
            <li>
              <NavLink>100 W 49th Ave,</NavLink>
            </li>
            <li>
              <NavLink>Vancouver, BC V5Y 2Z6</NavLink>
            </li>
          </ul>
        </nav>
      </article>
      {/*  */}
      <article>
        <p>© 2024 LearnEZ. All rights reserved</p>
      </article>
    </div>
  );
}
