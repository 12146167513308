import React from "react";
import { useNavigate } from 'react-router-dom';

const Suggestion = () => {
  const navigate = useNavigate();

  const handleClickContactUs = () => {
      navigate('/contact'); // Update with your actual route path
  };

  return (
    <div className="suggestion"> 
      <h1>Questions or Suggestions?</h1>
      <div className="suggestion-text">
          <p>Talk to Us</p>
          <p>
            Curious about LearnEZ? So talk to us! We are open to hear you, answer
            your questions and clear your doubts.
          </p>
          <p>We hear you!</p>
          <button  onClick={handleClickContactUs}>Contact Us</button>
      </div>
    </div>
  );
};

export default Suggestion;
