// src/components/TutorCard.js
import React from "react";
import "../css/TutorCard.css";

const TutorCard = ({ tutor, handleSelectTutor }) => {
  // Format the availability
  const formatAvailability = () => {
    if (Array.isArray(tutor.availability)) {
      return tutor.availability.map((slot, index) => (
        <span key={index}>{`${slot.day}: ${slot.start} - ${slot.end}`}</span>
      ));
    } else if (typeof tutor.availability === "string") {
      return <span>{tutor.availability || "Add availability"}</span>;
    }
    return <span>Add availability</span>;
  };

  return (
    <div className="tutor-card">
      <img
        src={`https://randomuser.me/api/portraits/men/${tutor._id % 100}.jpg`}
        alt={`${tutor.firstName} ${tutor.lastName}`}
        className="tutor-photo"
      />
      <h3>{`${tutor.firstName} ${tutor.lastName}`}</h3>
      <p className="tutor-descrip1">
        <strong>Specialization:</strong> {tutor.specialization}
      </p>
      <div className="tutor-descrip1">
        <strong>Availability:</strong> {formatAvailability()}
      </div>
      <p className="tutor-exper">
        <strong>Experience:</strong> {tutor.experience}
      </p>
      <button
        onClick={() => handleSelectTutor(tutor._id)}
        className="select-tutor-btn"
      >
        Select Tutor
      </button>
    </div>
  );
};

export default TutorCard;
