import React, { useState, useEffect } from "react";
import "../../css/exercise/exerciseCop03.css";

export const ComponentThree = ({ setScore }) => {
  const [droppedShapes, setDroppedShapes] = useState({
    triangle: null,
    circle: null,
    square: null,
  });
  const [scoreSet, setScoreSet] = useState(false);

  const handleDragStart = (e, shape) => {
    e.dataTransfer.setData("text/plain", shape);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, shape) => {
    e.preventDefault();
    const droppedShape = e.dataTransfer.getData("text/plain");

    setDroppedShapes((prev) => ({
      ...prev,
      [shape]: droppedShape === shape,
    }));
  };

  useEffect(() => {
    const allDropped = Object.values(droppedShapes).every(
      (value) => value !== null
    );

    if (allDropped && !scoreSet) {
      const allCorrect = Object.values(droppedShapes).every(
        (value) => value === true
      );
      setScore(allCorrect ? 1 : 0); // Pass 1 if all correct, otherwise 0
      setScoreSet(true); // Ensure score only sets once
    }
  }, [droppedShapes, scoreSet, setScore]);

  return (
    <div className="component-three">
      <h3>Please drag a shape to the missing placeholder</h3>

      <div className="draggable-container">
        <div
          className="draggable-box triangle"
          draggable
          onDragStart={(e) => handleDragStart(e, "triangle")}
        >
          Triangle
        </div>
        <div
          className="draggable-box circle"
          draggable
          onDragStart={(e) => handleDragStart(e, "circle")}
        >
          Circle
        </div>
        <div
          className="draggable-box square"
          draggable
          onDragStart={(e) => handleDragStart(e, "square")}
        >
          Square
        </div>
      </div>

      <div className="placeholders">
        <div
          className={`drop-area triangle ${
            droppedShapes.triangle ? "dropped" : ""
          }`}
          onDragOver={handleDragOver}
          onDrop={(e) => handleDrop(e, "triangle")}
        >
          {droppedShapes.triangle === true
            ? "✔️ Triangle Dropped!"
            : "Drop Triangle Here"}
        </div>
        <div
          className={`drop-area circle ${
            droppedShapes.circle ? "dropped" : ""
          }`}
          onDragOver={handleDragOver}
          onDrop={(e) => handleDrop(e, "circle")}
        >
          {droppedShapes.circle === true
            ? "✔️ Circle Dropped!"
            : "Drop Circle Here"}
        </div>
        <div
          className={`drop-area square ${
            droppedShapes.square ? "dropped" : ""
          }`}
          onDragOver={handleDragOver}
          onDrop={(e) => handleDrop(e, "square")}
        >
          {droppedShapes.square === true
            ? "✔️ Square Dropped!"
            : "Drop Square Here"}
        </div>
      </div>
    </div>
  );
};
