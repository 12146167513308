import React from "react";
import logo from "../assets/images/SVG_Files/Logo-updated_blue.svg";
import { BsDisplay } from "react-icons/bs";

const ResultPDF = () => {
  return (
    <div className="pdfresultcontent">
      <div className="logo">
        <img src={logo} alt="LearnEz Logo" className="logo-img" />
      </div>

      <h1><strong>Your Results Are Ready!</strong></h1>

      <div className="results-box">
          <p>Here's how you did:</p>

          <div className="result">
            <h3>Reading Results</h3>
            <p>You show a low risk for reading challenges</p>
            <div className="risk-bar">
              <div className="bar">
                <div className="filled-bar" style={{ width: "40%" }}></div>
              </div>
              <p className="percent">40%</p>
            </div>
            <div className="labels">
              <span>Low Risk</span>
              <span>High Risk</span>
            </div>
          </div>

          <div className="result">
            <h3>Writing Results</h3>
            <p>You show a possible risk for reading challenges</p>
            <div className="risk-bar">
              <div className="bar">
                <div className="filled-bar" style={{ width: "60%" }}></div>
              </div>
              <p className="percent">60%</p>
            </div>
            <div className="labels">
              <span>Low Risk</span>
              <span>High Risk</span>
            </div>
          </div>
        </div>
    </div>
  );
};

export default ResultPDF;
