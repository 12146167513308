import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo_updated_white.svg";
import profilePic from "../assets/images/SVG_Files/Tutor2.svg";

export default function Navbar() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isLoginDialogOpen, setIsLoginDialogOpen] = useState(false);
  const navigate = useNavigate();
  const uid = localStorage.getItem("user_uid");

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogin = (role) => {
    setIsLoggedIn(true);

    setIsDropdownOpen(false);
  };

  const handleLoginClick = () => {
    setIsLoginDialogOpen(true);
  };

  const handleProfileClick = () => {
    const userType = localStorage.getItem("userType");
    console.log("====== usertype = " + userType);

    if (userType === "parent") {
      navigate("/parent-profile");
    } else if (userType === "tutor") {
      navigate("/tutor-profile/:id");
    }
  };

  const handleLogOut = () => {
    localStorage.setItem("user_uid", "");
    navigate("/");
    setIsLoggedIn(false);
    setIsDropdownOpen(false);
    setIsMobileMenuOpen(false);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleLoginChoice = (choice) => {
    setIsLoggedIn(true);
    setIsLoginDialogOpen(false);
    setUserRole(choice);
    setIsDropdownOpen(false);
    console.log(`Logged in as ${choice}`);
    navigate(choice);
  };

  const handleCancelLogin = () => {
    setIsLoginDialogOpen(false);
  };

  const [userRole, setUserRole] = useState(null);

  return (
    <div className="NavBarContainer">
      <NavLink to="/">
        <div className="logo">
          <img src={logo} alt="LearnEz Logo" className="logo-img" />
        </div>
      </NavLink>

      <nav className="NavBar">
        <ul>
          <li>
            <Link to="/disability-landing">Free Testing</Link>
          </li>
          <li>
            <Link to="/live-tutoring">Live Tutoring</Link>
          </li>
          {(!isLoggedIn || userRole !== "tutor") && (
            <li>
              <NavLink to="/tutorLogin">For Tutors</NavLink>
            </li>
          )}
          <li>
            <NavLink to="/about-us">About</NavLink>
          </li>
          <li>
            <NavLink to="/contact">Contact</NavLink>
          </li>
        </ul>
      </nav>

      {/* Mobile Menu Icon */}
      <i
        className="fa-solid fa-bars menu-icon"
        onClick={toggleMobileMenu}
        aria-label="Toggle Menu"
      ></i>

      {/* Large Screen - Button or Profile Section */}
      {uid !== null && uid !== "" ? (
        <div className="profile-section">
          <span className="divider"></span>
          <img
            src={profilePic}
            alt="User Profile"
            className="profile-img"
            onClick={toggleDropdown}
          />
          {isDropdownOpen && (
            <div className="dropdown">
              <a
                href="#my-account"
                onClick={handleProfileClick}
                className="dropdown-item"
              >
                My Account
              </a>
              <a
                href="#logout"
                onClick={handleLogOut}
                className="dropdown-item"
              >
                Logout
              </a>
            </div>
          )}
        </div>
      ) : (
        <button className="btnLogin" onClick={handleLoginClick}>
          Login
        </button>
      )}

      {/* Large Screen - Login Dialog */}
      {isLoginDialogOpen && !isLoggedIn && !isMobileMenuOpen && (
        <div className="login-dialog">
          <div className="dialog-content">
            <h3>Login as</h3>
            <button
              className="dialog-button"
              onClick={() => handleLoginChoice("/Parent")}
            >
              Parent
            </button>
            <button
              className="dialog-button"
              onClick={() => handleLoginChoice("/tutorLogin")}
            >
              Tutor
            </button>
            <button className="dialog-button" onClick={handleCancelLogin}>
              Cancel
            </button>
          </div>
        </div>
      )}

      {/* Mobile Menu */}
      {/* {isMobileMenuOpen && (
        <nav className="NavBarMobile">
          <ul>
            <li>
              <Link to="/disability-landing">Free Testing</Link>
            </li>
            <li>
              <Link to="/live-tutoring">Live Tutoring</Link>
            </li>
            <li>
              <NavLink to="/tutorLogin">For Tutors</NavLink>
            </li>
            <li>
              <NavLink to="/about-us">About</NavLink>
            </li>
            <li>
              <NavLink to="/contact">Contact</NavLink>
            </li>
          </ul>
          {!isLoggedIn ? (
            <button className="btnLogin" onClick={handleLoginClick}>
              Login
            </button>
          ) : (
            <button className="btnLogin" onClick={handleLogOut}>
              Logout
            </button>
          )}
        </nav>
      )} */}
      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <nav className="NavBarMobile">
          <ul>
            <li>
              <Link to="/disability-landing">Free Testing</Link>
            </li>
            <li>
              <Link to="/live-tutoring">Live Tutoring</Link>
            </li>
            {/* Display "For Tutors" link only if the user is not logged in as a tutor */}
            {(!isLoggedIn || userRole !== "tutor") && (
              <li>
                <NavLink to="/tutorLogin">For Tutors</NavLink>
              </li>
            )}
            <li>
              <NavLink to="/about-us">About</NavLink>
            </li>
            <li>
              <NavLink to="/contact">Contact</NavLink>
            </li>
          </ul>
          {!isLoggedIn ? (
            <button className="btnLogin" onClick={handleLoginClick}>
              Login
            </button>
          ) : (
            <div className="profile-section">
              <img
                src={profilePic}
                alt="User Profile"
                className="profile-img"
                onClick={toggleDropdown}
              />
              {isDropdownOpen && (
                <div className="dropdown">
                  <a href="#my-account" className="dropdown-item">
                    My Account
                  </a>
                  <a
                    href="#logout"
                    onClick={handleLogOut}
                    className="dropdown-item"
                  >
                    Logout
                  </a>
                </div>
              )}
            </div>
          )}
        </nav>
      )}

      {/* Mobile Login Dialog */}
      {isLoginDialogOpen && !isLoggedIn && isMobileMenuOpen && (
        <div className="login-dialog">
          <div className="dialog-content">
            <h3>Login as</h3>
            <button
              className="dialog-button"
              onClick={() => handleLoginChoice("/Parent")}
            >
              Parent
            </button>
            <button
              className="dialog-button"
              onClick={() => handleLoginChoice("/tutorLogin")}
            >
              Tutor
            </button>

            <button className="dialog-button" onClick={handleCancelLogin}>
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
