import React from "react";
import Navbar from "../components/NavBar";
import StudentProfilePublicCard from "../components/StudentProfilePublicCard";
import HomeFooter from "../components/HomeFooter";
import "../css/StudentProfile.css";

const StudentProfilePublic = ({ child }) => {
  return (
    <div className="StudentProfilePage">
      <header>
        <Navbar />
      </header>
      <main>
        <StudentProfilePublicCard child={child} />
      </main>
      <footer>
        <HomeFooter />
      </footer>
    </div>
  );
};

export default StudentProfilePublic;
