import React from "react";
import "../Button/Button.css";
import { useNavigate } from "react-router-dom";

export default function ButtonChildProfile({ child }) {
  const navigate1 = useNavigate();

  const handleClickStudentsProfilePublic = () => {
    navigate1("/student-profile-public", { state: { child } }); // Update with your actual route path
  };
  return (
    <div className="ButtonChildProfile">
      <button onClick={handleClickStudentsProfilePublic}>Profile</button>
    </div>
  );
}
