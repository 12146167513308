import React, { useState } from "react";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  //input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Submitted:", formData);
    // Add your form submission logic here
  };

  return (
    <div className="contact-form">
      <div className="formTxt">
        <h1>
          Get in touch with <br />
          LearnEZ
        </h1>
        <p>
          We’re here to support you on your learning journey. Whether you have
          questions about our platform, need help with a specific learning
          challenge, or just want to share feedback, we’d love to hear from you.
          Reach out to us, and we'll get back to you as soon as possible.
        </p>
      </div>

      <form className="form-template" onSubmit={handleSubmit}>
        <h2>How can we help ?</h2>

        <label htmlFor="name"> Full Name:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />

        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />

        <label htmlFor="message">Message:</label>
        <textarea
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          rows="8"
          required
          style={{ resize: 'none'}}
        ></textarea>

        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default ContactForm;
