// StudentProfileCard.js
import React from "react";
import { useLocation } from "react-router-dom";
import "../css/StudentProfileCard.css";
import TrainingTutorCard from "../components/TrainingTutorCard";
import ButtonChildExercises from "./Button/ButtonChildExercises";
import ButtonChildAssessment from "./Button/ButtonChildAssessment";
import StudentsListProgressCard from "../components/StudentsListProgressCard";
import StudentProfileDetailsCard from "../components/StudentProfileDetailsCard";

export default function StudentProfileCard() {
  const location = useLocation();
  const { child } = location.state || {};

  if (!child) {
    return <div>No student data available.</div>;
  }

  return (
    <div className="students-profile-maxwidth">
      <div className="students-profile-container">
        <h2 className="student-Montserrat">Student Profile</h2>
        <div className="student-profile-card">
          <StudentProfileDetailsCard child={child} />
          <StudentsListProgressCard child={child} />
          <div className="TrainingTutorCardInput">
            <TrainingTutorCard />
          </div>
          <div className="student-profile-buttoms">
            <ButtonChildExercises child={child} />
            <ButtonChildAssessment child={child} />
          </div>
        </div>
      </div>
    </div>
  );
}
