import React, { useEffect, useState, useRef } from "react";
import appleImage from "../../assets/exerSVG/apple.svg";
import "../../css/exercise/exerciseCop09.css";

const ComponentNine = ({ setScore }) => {
  const [columnOneCount, setColumnOneCount] = useState(0);
  const [columnTwoCount, setColumnTwoCount] = useState(0);
  const [userInput, setUserInput] = useState("");
  const [isCorrect, setIsCorrect] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false); // State to disable input
  const inputRef = useRef(null); // Ref for the input field

  useEffect(() => {
    // Generate random counts for apples between 1 and 9
    const randomColumnOne = Math.floor(Math.random() * 9) + 1;
    const randomColumnTwo = Math.floor(Math.random() * 9) + 1;

    setColumnOneCount(randomColumnOne);
    setColumnTwoCount(randomColumnTwo);

    // Log the generated counts for debugging
    console.log(
      `Column One Apples: ${randomColumnOne}, Column Two Apples: ${randomColumnTwo}`
    );

    // Focus the input field on component mount
    inputRef.current.focus();
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;

    if (!isDisabled) {
      // Check if input is disabled
      setUserInput(value);

      const totalApples = columnOneCount + columnTwoCount;
      const totalApplesString = totalApples.toString();

      if (value.length === totalApplesString.length && /^[0-9]*$/.test(value)) {
        if (parseInt(value, 10) === totalApples) {
          setIsCorrect(true);
          setScore(1); // Increment score if correct
        } else {
          setIsCorrect(false);
          setScore(0); // Set score to 0 if wrong
        }

        setIsDisabled(true); // Disable input after the first entry
      } else {
        setIsCorrect(null); // Reset validation state for incomplete input
      }
    }
  };

  const renderApples = (count) => {
    const rows = [];
    const maxRows = Math.min(count, 5); // Limit to a maximum of 5 rows
    let remainingApples = count;

    for (let i = maxRows; i > 0; i--) {
      const applesInRow = Math.min(i, remainingApples);
      rows.push(
        <div key={rows.length} className="apple-row">
          {[...Array(applesInRow)].map((_, index) => (
            <img key={index} src={appleImage} alt="Apple" className="apple" />
          ))}
        </div>
      );
      remainingApples -= applesInRow;
      if (remainingApples <= 0) break;
    }
    return rows;
  };

  return (
    <div className="component-nine">
      <div className="question">How many apples do we have?</div>
      <div className="columns">
        <div className="column">{renderApples(columnOneCount)}</div>
        <div className="column plus-column">+</div>
        <div className="column">{renderApples(columnTwoCount)}</div>
      </div>
      <div className="input-container">
        <input
          type="number"
          value={userInput}
          onChange={handleInputChange}
          className={
            isCorrect === true
              ? "correct"
              : isCorrect === false
              ? "incorrect"
              : ""
          }
          ref={inputRef}
          disabled={isDisabled}
        />
      </div>
      {isCorrect === true && <p className="feedback correct">Good job!</p>}
      {isCorrect === false && (
        <p className="feedback incorrect">Sorry, that's not correct.</p>
      )}
    </div>
  );
};

export default ComponentNine;



