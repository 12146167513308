import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Pages
import Home from "./pages/Home";
import ParentLogin from "./pages/ParentLogin";
import ParentSignup from "./pages/parentSignup";
import Contact from "./pages/Contact";
import TutorLogin from "./pages/LoginTutor";
import TutorRegister from "./pages/TutorRegister";
import TutorProfile from "./pages/TutorProfile"; // Cambia ProfileTutor por TutorProfile
import TutorList from "./pages/TutorList";
import ExerciseView from "./pages/ExerciseView";
import StudentsList from "./pages/StudentsList";
import ProfileTutorPublic from "./pages/ProfileTutorPublic";
import StudentProfile from "./pages/StudentProfile";
import StudentProfilePublic from "./pages/StudentProfilePublic";
import ParentProfile from "./pages/ParentProfile";
import TutorEdit from "./pages/TutorEdit"; // Importa TutorEdit
import AboutUs from "./pages/AboutUs";
import DisabilityLanding from "./pages/DisabilityLanding";
import DisabilityTest from "./pages/DisabilityTest";
import DisabilityTestResult from "./pages/DisabilityTestResult";
import DisabilityTestProgress from "./pages/DisabiltyTestProgress";
import DisabilityWeeklyAssessment from "./pages/DisabilityWeeklyAssessment";
import DisabilityWeeklyAssessmentResult from "./pages/DisabiltyWeeklyAssessmentResult";
import ZoomWaitingLink from "./pages/ZoomWaitingLink";
import DisabilityTestDyslexia from "./pages/DisabilityTestDyslexia";
import ResultPDF from "./pages/ResultPDF";
import TestResult from "./pages/TestResult";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/parent" element={<ParentLogin />} />
        <Route path="/parentSign" element={<ParentSignup />} />
        <Route path="/tutorLogin" element={<TutorLogin />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/zoom-waiting-link" element={<ZoomWaitingLink />} />
        <Route path="/tutorRegister" element={<TutorRegister />} />
        <Route path="/tutor-profile/:id" element={<TutorProfile />} />{" "}
        {/* Cambiado aquí */}
        <Route path="/live-tutoring" element={<TutorList />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/exercise" element={<ExerciseView />} />
        <Route path="/tutor-profile/:id" element={<TutorProfile />} />
        <Route path="/students-list" element={<StudentsList />} />
        <Route path="/student-profile" element={<StudentProfile />} />
        <Route path="/parent-profile" element={<ParentProfile />} />
        <Route path="/tutor-edit/:id" element={<TutorEdit />} />
        <Route path="/disability-landing" element={<DisabilityLanding />} />
        <Route path="/disability-test" element={<DisabilityTest />} />
        <Route
          path="disability-test-Dyslexia"
          element={<DisabilityTestDyslexia />}
        />
        <Route path="/result-PDF" element={<ResultPDF />} />
        <Route
          path="disability-test-result"
          element={<DisabilityTestResult />}
        />
        <Route
          path="disability-test-progress"
          element={<DisabilityTestProgress />}
        />
        <Route
          path="disability-weekly-assessment"
          element={<DisabilityWeeklyAssessment />}
        />
        <Route
          path="disability-weekly-assessment-result"
          element={<DisabilityWeeklyAssessmentResult />}
        />
        <Route
          path="/student-profile-public"
          element={<StudentProfilePublic />}
        />
        <Route
          path="/tutor-profile-public/:id"
          element={<ProfileTutorPublic />}
        />
        <Route path="/test-result" element={<TestResult />} />
      </Routes>
    </Router>
  );
}

export default App;
