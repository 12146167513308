import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "./css/AboutUsHero.css"
import "./css/TeamMembers.css"
// import "./css/header.css";
import "./css/NavBar.css"
import "./css/DisabilityHeroDyslexia.css";
import "./css/DisabilityHero.css"
import "./css/Contact.css";
import "./css/ZoomWaitingRoom.css";
import "./css/AboutUsSuggestion.css";
import "./css/DisabilityHero.css";
import "./css/DisabilityTestResult.css"
import "./css/WeeklyAssessmentResult.css"
import "./css/resultpdf.css"
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
