import React from "react";
import { useNavigate } from "react-router-dom";
import "../Button/Button.css";

export default function ParentSignUp() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/parentSign"); // navigate to the ExerciseView page
  };

  return (
    <div className="parentSignUp">
      <button onClick={handleClick}>Parent? Sign up</button>
    </div>
  );
}
