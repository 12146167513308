// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
 apiKey: "AIzaSyCiP_p2mXHqwLPncmYLdOAe4gHdd8s5S2A",
 authDomain: "learnez-8bdb0.firebaseapp.com",
 projectId: "learnez-8bdb0",
 storageBucket: "learnez-8bdb0.appspot.com",
 messagingSenderId: "609519847204",
 appId: "1:609519847204:web:2857fbbfc298a1cd42d5b1",
 measurementId: "G-7HPLECYWVP"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const auth = getAuth(app);  // Export the auth object