import React from "react";

const DisabilityHeroDyslexia = () => {
  return (
    <div className="assessment-container">
      <h1>Complete Child Learning Skills Assessment</h1>
      <div class="assessment-content">
        <p className="instructions">
          <strong>Instructions for Parents:</strong> Please make sure your child
          completes the test on their own, without assistance. The test is best
          experienced on a touch-screen device like a tablet or iPad.
        </p>
        <div className="question-box">
          <p>1. Can you select the letter 'b'?</p>
          <i className="fa-sharp fa-thin fa-volume"></i>
          <div className="options-box">
            <span>b</span>
            <span>o</span>
            <span>c</span>
            <span>p</span>
            <span>a</span>
            <span>d</span>
          </div>
        </div>
        <div className="progress-section">
          <div className="progress-bar">
            <div className="progress" style={{ width: "10%" }}></div>
          </div>
          <p>Step 1 of 10</p>
        </div>
        <button className="next-btn">Next</button>
      </div>
    </div>
  );
};

export default DisabilityHeroDyslexia;
