import React, { useState } from "react";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../services/firebase";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/NavBar";
import HomeFooter from "../components/HomeFooter";
import "../css/RegisterPages.css";

// import React, { useState } from "react";
// import { createUserWithEmailAndPassword } from "firebase/auth"; // Import Firebase auth
// import { auth } from "../services/firebase"; // Import your Firebase setup
// import { useNavigate } from "react-router-dom"; // Importa useNavigate
// import Navbar from "../components/NavBar";
// import HomeFooter from "../components/HomeFooter";
// import "../css/RegisterPages.css";

// const TutorRegister = () => {
//   const [formData, setFormData] = useState({
//     firstName: "",
//     lastName: "",
//     email: "",
//     address: "",
//     password: "",
//     city: "",
//     state: "",
//     postalCode: "",
//     phone: "",
//     birthDate: "",
//     certificateNumber: "",
//     subjects: "",
//     experience: "",
//     specialization: "",
//     availability: {
//       Monday: [],
//       Tuesday: [],
//       Wednesday: [],
//       Thursday: [],
//       Friday: [],
//       Saturday: [],
//       Sunday: [],
//     },
//     qualifiedTeacher: false,
//   });

//   const [errorMessage, setErrorMessage] = useState("");
//   const [expandedDay, setExpandedDay] = useState(null);
//   const navigate = useNavigate();

//   const handleChange = (e) => {
//     const { name, value, type, checked } = e.target;
//     setFormData({
//       ...formData,
//       [name]: type === "checkbox" ? checked : value,
//     });
//   };

//   const handleToggleDay = (day) => {
//     setExpandedDay(expandedDay === day ? null : day);
//   };

//   const handleTimeChange = (day, index, field, value) => {
//     setFormData((prevData) => {
//       const updatedTimes = [...prevData.availability[day]];
//       updatedTimes[index][field] = value;
//       return {
//         ...prevData,
//         availability: {
//           ...prevData.availability,
//           [day]: updatedTimes,
//         },
//       };
//     });
//   };

//   const addTimeSlot = (day) => {
//     setFormData((prevData) => ({
//       ...prevData,
//       availability: {
//         ...prevData.availability,
//         [day]: [...prevData.availability[day], { start: "", end: "" }],
//       },
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (formData.password.length < 6) {
//       setErrorMessage("Password must be at least 6 characters long.");
//       return;
//     }

//     try {
//       const userCredential = await createUserWithEmailAndPassword(
//         auth,
//         formData.email,
//         formData.password
//       );
//       const uid = userCredential.user.uid;

//       // Convert availability to an array format for MongoDB
//       const formattedAvailability = Object.entries(
//         formData.availability
//       ).flatMap(([day, slots]) =>
//         slots.map((slot) => ({ day, start: slot.start, end: slot.end }))
//       );

//       const response = await fetch(
//         "http://localhost:5001/api/tutors/register",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({
//             ...formData,
//             firebaseUid: uid, // Enviar el UID del tutor al backend
//             availability: formattedAvailability,
//             userType: "tutor", // Enviar el tipo de usuario como tutor
//           }),
//         }
//       );

//       const data = await response.json();
//       if (response.ok) {
//         navigate(`/tutor-profile/${data.tutorId}`);
//       } else {
//         console.log("Error al registrar tutor en MongoDB:", data.message);
//       }
//     } catch (error) {
//       console.error("Error en el proceso de registro:", error);
//       setErrorMessage(error.message);
//     }
//   };

//   return (
//     <>
//       <header>
//         <Navbar />
//       </header>
//       <main className="TutorRegisterMain">
//         <h2>Tutor Registration Form</h2>
//         <div className="register-container">
//           <p>
//             Please complete the registration form below to register as a tutor.
//           </p>
//           {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
//           <form className="register-form" onSubmit={handleSubmit}>
//             <div className="input-group">
//               <label>Your Name:</label>
//               <div className="name-fields">
//                 <input
//                   type="text"
//                   name="firstName"
//                   placeholder="First Name"
//                   value={formData.firstName}
//                   onChange={handleChange}
//                   required
//                 />
//                 <input
//                   type="text"
//                   name="lastName"
//                   placeholder="Last Name"
//                   value={formData.lastName}
//                   onChange={handleChange}
//                   required
//                 />
//               </div>
//             </div>

//             <div className="input-group">
//               <label>Email:</label>
//               <input
//                 type="email"
//                 name="email"
//                 placeholder="Enter your email"
//                 value={formData.email}
//                 onChange={handleChange}
//                 required
//               />
//             </div>

//             <div className="input-group">
//               <label>Password:</label>
//               <input
//                 type="password"
//                 name="password"
//                 placeholder="Enter your password"
//                 value={formData.password}
//                 onChange={handleChange}
//                 required
//               />
//             </div>

//             <div className="input-group">
//               <label>Address:</label>
//               <input
//                 type="text"
//                 name="address"
//                 value={formData.city}
//                 onChange={handleChange}
//                 required
//               />
//               <label>State / Province:</label>
//               <input
//                 type="text"
//                 name="state"
//                 value={formData.state}
//                 onChange={handleChange}
//                 required
//               />
//             </div>

{
  /* <div className="input-group">
              <label>Date of Birth:</label>
              <input
                type="date"
                name="birthDate"
                value={formData.birthDate}
                onChange={handleChange}
                required
              />
            </div> */
}

// <div className="input-group">
//   <label>Certificate Number:</label>
//   <input
//     type="text"
//     name="certificateNumber"
//     value={formData.certificateNumber}
//     onChange={handleChange}
//   />
// </div>

{
  /* <div className="input-group">
              <label>Subject(s) and Levels Offered:</label>
              <textarea
                name="subjects"
                value={formData.subjects}
                onChange={handleChange}
              ></textarea>
            </div> */
}

{
  /* <div className="input-group">
              <label>Relevant Experience (Teaching/Tutoring):</label>
              <textarea
                name="experience"
                value={formData.experience}
                onChange={handleChange}
              ></textarea>
            </div> */
}

// <div className="input-group">
//   <label>Do you have Qualified Teacher Status?</label>

//   <label className="checkbox-wrapper">
//     <label className="custom-checkbox">
//       <input
//         type="radio"
//         name="qualifiedTeacher"
//         checked={formData.qualifiedTeacher === "yes"}
//         onChange={handleChange}
//         value="yes"
//       />
//       <span className="checkmark"></span>
//     </label>
//     <span className="checkbox-label">Yes</span>
//   </label>

//   <label className="checkbox-wrapper">
//     <label className="custom-checkbox">
//       <input
//         type="radio"
//         name="qualifiedTeacher"
//         checked={formData.qualifiedTeacher === "no"}
//         onChange={handleChange}
//         value="no"
//       />
//       <span className="checkmark"></span>
//     </label>
//     <span className="checkbox-label">No</span>
//   </label>
// </div>

{
  /* <div className="input-group">
              <label>Qualification:</label>
              <input
                type="text"
                name="specialization"
                placeholder="Specialization"
                value={formData.specialization}
                onChange={handleChange}
                required
              />
            </div> */
}

{
  /* Disponibilidad */
}
//             <div className="input-group">
//               <label>Availability:</label>
//               <input
//                 type="text"
//                 name="availability"
//                 placeholder="e.g. Monday to Friday, 9AM - 2PM"
//                 value={formData.availability}
//                 onChange={handleChange}
//                 required
//               />
//             </div>

//             <div className="input-group">
//               <label>Upload your files here:</label>
//               <input type="file" name="documents" multiple />
//             </div>

//             <button type="submit">Submit Registration</button>
//           </form>
//         </div>
//       </main>
//       <footer>
//         <HomeFooter />
//       </footer>
//     </>
//   );
// };

// export default TutorRegister;

const TutorRegister = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    address: "",
    city: "",
    state: "",
    postalCode: "",
    phone: "",
    birthDate: "",
    certificateNumber: "",
    subjects: "",
    experience: "",
    specialization: "",
    availability: {
      selectedDays: [],
      timeSlots: {},
    },
    qualifiedTeacher: false,
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [showAvailability, setShowAvailability] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("+1 ");
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    let newValue = type === "checkbox" ? checked : value;
  
    if (name === "phone") {
      // Ensure phone number starts with "+1 " and contains only numbers after the initial "+1 "
      if (!newValue.startsWith("+1 ")) {
        newValue = "+1 ";
      }
  
      const numericPart = newValue.slice(3).replace(/\D/g, "");
      if (numericPart.length <= 10) {
        newValue = "+1 " + numericPart;
      } else {
        newValue = formData.phone; // Prevents setting an invalid phone format
      }
    }
  
    setFormData({
      ...formData,
      [name]: newValue,
    });
  };
  

  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const timeSlots = ["9", "10", "11", "12", "13", "14", "15", "16", "17"];

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleDaySelection = (day) => {
    setFormData((prevData) => ({
      ...prevData,
      availability: {
        ...prevData.availability,
        selectedDays: prevData.availability.selectedDays.includes(day)
          ? prevData.availability.selectedDays.filter((d) => d !== day)
          : [...prevData.availability.selectedDays, day],
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.password.length < 6) {
      setErrorMessage("Password must be at least 6 characters long.");
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );
      const uid = userCredential.user.uid;

      // Format availability for the backend
      const formattedAvailability = formData.availability.selectedDays.map(
        (day) => ({
          day,
          timeSlots: formData.availability.timeSlots[day] || [],
        })
      );

      const response = await fetch(
        "http://localhost:5001/api/tutors/register",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...formData,
            firebaseUid: uid,
            availability: formattedAvailability,
            userType: "tutor",
          }),
        }
      );

      const data = await response.json();
      if (response.ok) {
        navigate(`/tutor-profile/${data.tutorId}`);
      } else {
        console.log("Error registering tutor in MongoDB:", data.message);
      }
    } catch (error) {
      console.error("Registration error:", error);
      setErrorMessage(error.message);
    }
  };

  return (
    <>
      <header>
        <Navbar />
      </header>
      <main className="TutorRegisterMain">
        <h2>Tutor Registration Form</h2>
        <div className="register-container">
          <p>
            Please complete the registration form below to register as a tutor.
          </p>
          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
          <form className="register-form" onSubmit={handleSubmit}>
            {/* Name Fields */}
            <div className="input-group">
              <label>Your Name:</label>
              <div className="name-fields">
                <input
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            {/* Email and Password Fields */}
            <div className="input-group">
              <label>Email:</label>
              <input
                type="email"
                name="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>

            <div className="input-group">
              <label>Password:</label>
              <input
                type="password"
                name="password"
                placeholder="Enter your password"
                value={formData.password}
                onChange={handleChange}
                required
              />
            </div>

            <div className="input-group">
              <div className="sub-group-container-1">
                <label>Address:</label>
                <input
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="input-group sub-input-group">
                <label> City: </label>
                <input
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  required
                />
                <label>State / Province:</label>
                <input
                  type="text"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="input-group">
              <label>Phone number</label>
              <input
                type="tel"
                name="phone"
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                value={formData.phone}
                placeholder="+1 123 456 7890"
                onChange={handleInputChange}
                maxLength={14}
                required
              />
            </div>

            {/* Certificate Number */}
            <div className="input-group">
              <label>Certificate Number:</label>
              <input
                type="text"
                name="certificateNumber"
                placeholder="Certificate Number"
                value={formData.certificateNumber}
                onChange={handleChange}
              />
            </div>

            <div className="input-group">
              <label>Qualified Teacher Status:</label>
              <label className="checkbox-wrapper">
                <input
                  type="radio"
                  name="qualifiedTeacher"
                  checked={formData.qualifiedTeacher === true}
                  onChange={() =>
                    setFormData({ ...formData, qualifiedTeacher: true })
                  }
                />
                <span>Yes</span>
              </label>
              <label className="checkbox-wrapper">
                <input
                  type="radio"
                  name="qualifiedTeacher"
                  checked={formData.qualifiedTeacher === false}
                  onChange={() =>
                    setFormData({ ...formData, qualifiedTeacher: false })
                  }
                />
                <span>No</span>
              </label>
            </div>

            {/* Availability */}
            <div className="input-group">
              <label>Availability:</label>
              <button
                type="button"
                onClick={() => setShowAvailability(!showAvailability)}
              >
                Add availability
              </button>
            </div>

            {/* Availability Selection */}
            {showAvailability && (
              <div className="availability-section">
                <div className="day-selection">
                  {daysOfWeek.map((day) => (
                    <button
                      key={day}
                      onClick={() => handleDaySelection(day)}
                      className={`day-button ${
                        formData.availability.selectedDays.includes(day)
                          ? "selected"
                          : ""
                      }`}
                    >
                      {day}
                    </button>
                  ))}
                </div>

                {formData.availability.selectedDays.length > 0 && (
                  <div className="time-grid">
                    <table>
                      <thead>
                        <tr>
                          <th>Time</th>
                          {formData.availability.selectedDays.map((day) => (
                            <th key={day}>{day.slice(0, 3)}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {timeSlots.map((time) => (
                          <tr key={time}>
                            <td>{`${time}:00`}</td>
                            {formData.availability.selectedDays.map((day) => (
                              <td key={`${day}-${time}`}>
                                <input
                                  type="checkbox"
                                  onChange={(e) => {
                                    setFormData((prevData) => ({
                                      ...prevData,
                                      availability: {
                                        ...prevData.availability,
                                        timeSlots: {
                                          ...prevData.availability.timeSlots,
                                          [day]: e.target.checked
                                            ? [
                                                ...(prevData.availability
                                                  .timeSlots[day] || []),
                                                time,
                                              ]
                                            : (
                                                prevData.availability.timeSlots[
                                                  day
                                                ] || []
                                              ).filter((t) => t !== time),
                                        },
                                      },
                                    }));
                                  }}
                                  checked={
                                    formData.availability.timeSlots[
                                      day
                                    ]?.includes(time) || false
                                  }
                                />
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            )}

            <button type="submit">Submit Registration</button>
          </form>
        </div>
      </main>
      <footer>
        <HomeFooter />
      </footer>
    </>
  );
};

export default TutorRegister;
