import React, { useState } from "react";
import dogImage from "../../assets/exerSVG/black_dog.svg";
import "../../css/exercise/exerciseCop06.css";

export const ComponentSix = ({ setScore }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [isCorrect, setIsCorrect] = useState(null);
  const [feedback, setFeedback] = useState("");

  const handleOptionClick = (option) => {
    if (selectedOption) return;

    setSelectedOption(option);
    const correct = option === "dog";
    setIsCorrect(correct);
    setFeedback(correct ? "Hurrah, you did it right!" : "Whoops, wrong answer");
    setScore(correct ? 1 : 0); // Set score based on answer correctness
  };

  return (
    <div className="exercise-six">
      <img
        src={dogImage}
        alt="Dog"
        className={`dog-image ${
          isCorrect === null
            ? ""
            : isCorrect
            ? "correct-border"
            : "incorrect-border"
        }`}
      />
      <h3>What is this?</h3>
      <div className="options">
        {["dog", "bog", "log"].map((option) => (
          <span
            key={option}
            onClick={() => handleOptionClick(option)}
            className={`option-text ${
              selectedOption === option
                ? isCorrect
                  ? "correct"
                  : "incorrect"
                : ""
            }`}
          >
            {option}
          </span>
        ))}
      </div>
      {feedback && <p className="feedback-text">{feedback}</p>}
    </div>
  );
};
