import React from "react";
import Navbar from "../components/NavBar";
import Footer from "../components/HomeFooter";


const DisabilityTest = () => {
  return (
    <>
      <Navbar />
      <div>
        <section class="assessment">
          <h2>Complete Child Learning Skills Assessment</h2>
          <p class="instructions">
            <strong>Instructions for Parents:</strong> Please make sure your
            child completes the test on their own...
          </p>

          <div class="test-question">
            <div class="progress-bar">
              <span>Step 1 of 10</span>
            </div>
            <p>1. Which word is for 'dog'?</p>
            <div class="answers">
              <button>dog</button>
              <button>bog</button>
              <button>fog</button>
              <button>cog</button>
              <button>god</button>
            </div>
            <button class="next-btn">Next</button>
          </div>
        </section>

        {/* This is where the nested content will render */}
        {/* <Outlet /> */}
      </div>
      {/* here it gets replaced*/}
      {/* <Outlet /> */}
      {/* here */}

      <Footer></Footer>
    </>
  );
};

export default DisabilityTest;
