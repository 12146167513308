// TutorProfile.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Navbar from "../components/NavBar";
import HomeFooter from "../components/HomeFooter";
import ProfileTutorCard from "../components/ProfileTutorCard";
import '../css/ProfilePage.css';

const TutorProfile = () => {
  const { id } = useParams();
  const [tutor, setTutor] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!id) {
      console.error("No tutor ID provided.");
      setLoading(false);
      return;
    }

    const fetchTutorDetails = async () => {
      try {
        const response = await fetch(`http://localhost:5001/api/tutors/${id}`);
        if (!response.ok) {
          throw new Error('Failed to fetch tutor data');
        }
        const data = await response.json();
        setTutor(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching tutor details:", error);
        setLoading(false);
      }
    };

    fetchTutorDetails();
  }, [id]);

  if (loading) {
    return <p>Loading tutor details...</p>;
  }

  return (
    <div className='tutor-profile-page'>
      <header>
        <Navbar />
      </header>
      <main>
        {tutor ? (
          <ProfileTutorCard tutor={tutor} />
        ) : (
          <p>No tutor data available</p>
        )}
      </main>
      <footer>
        <HomeFooter /> 
      </footer>
    </div>
  );
};

export default TutorProfile;


// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import Navbar from "../components/NavBar";
// import HomeFooter from "../components/HomeFooter";
// import ProfileTutorCard from "../components/ProfileTutorCard";
// import '../css/ProfilePage.css';

// const TutorProfile = () => {
//   const { id } = useParams(); // Obtiene el ID del tutor desde la URL
//   console.log("Tutor ID:", id); // Verifica si el ID se obtiene correctamente

//   const [tutor, setTutor] = useState(null); 
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     if (!id) {
//       console.error("No tutor ID provided.");
//       setLoading(false);
//       return;
//     }

//     const fetchTutorDetails = async () => {
//       try {
//         const response = await fetch(`http://localhost:5001/api/tutors/${id}`);
//         if (!response.ok) {
//           throw new Error('Failed to fetch tutor data');
//         }
//         const data = await response.json();
//         setTutor(data);
//         setLoading(false);
//         console.log("Tutor data received:", data);
//       } catch (error) {
//         console.error("Error fetching tutor details:", error);
//         setLoading(false);
//       }
//     };

//     fetchTutorDetails();
//   }, [id]);

//   if (loading) {
//     return <p>Loading tutor details...</p>;
//   }

//   return (
//     <div className='tutor-profile-page'>
//       <header>
//         <Navbar />
//       </header>
//       <main>
//         {tutor ? (
//           <ProfileTutorCard tutor={tutor} /> // Pasamos tutor como prop
//         ) : (
//           <p>No tutor data available</p>
//         )}
//       </main>
//       <footer>
//         <HomeFooter /> 
//       </footer>
//     </div>
//   );
// };

// export default TutorProfile;