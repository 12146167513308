import React from "react";
import Navbar from "../components/NavBar";
import ProfileTutorPublicCard from "../components/ProfileTutorPublicCard";
import HomeFooter from "../components/HomeFooter";
import "../css/ProfilePage.css"; // Asegúrate de tener el archivo CSS adecuado
import { useParams } from "react-router-dom";

const TutorProfilePublic = () => {
  const { id } = useParams();

  return (
    <div className="tutor-profile-page">
      <header>
        <Navbar />
      </header>
      <main>
        <ProfileTutorPublicCard id={id} />
      </main>
      <footer>
        <HomeFooter />
      </footer>
    </div>
  );
};

export default TutorProfilePublic;
