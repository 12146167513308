import React, { useState } from "react";
import "../Button/Button.css";
import axios from "axios"; // Make sure to install axios (npm install axios)
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation

export default function ButtonTutorConfirm({ tutor, selectedDate }) {
  const navigate = useNavigate(); // Initialize navigate hook
  const [showPopup, setShowPopup] = useState(false); // State for controlling popup visibility

  const handleConfirmClick = async () => {
    try {
      // Retrieve parent_id from localStorage
      const parent_id = localStorage.getItem("user_uid");

      const myDay = selectedDate
        .toLocaleString("en-US", { weekday: "long" })
        .toLowerCase();
      const myTimeSlot = selectedDate
        .toLocaleString("en-US", { hour: "numeric", hour12: true })
        .toUpperCase();

      // Format the data needed for the booking
      const bookingData = {
        parent_id: parent_id,
        tutor_id: tutor.firebaseUid, // Assuming tutor data has a firebaseUid field
        day: myDay,
        timeSlot: myTimeSlot,
        date: selectedDate,
      };

      // Send the booking data to the backend
      const response = await axios.post(
        "http://localhost:5001/api/bookings/book",
        bookingData
      );

      // Handle response
      if (response.status === 201) {
        // Show the popup
        setShowPopup(true);

        // After 2 seconds, navigate to the parent profile page
        setTimeout(() => {
          navigate("/parent-profile"); // Navigate to the parent profile page
        }, 2000); // Wait 2 seconds before navigating
      } else {
        alert("Booking failed. Please try again.");
      }
    } catch (error) {
      console.error("Error creating booking:", error);
      alert("An error occurred while creating the booking.");
    }
  };

  return (
    <div className="ButtonTutorConfirm">
      <button onClick={handleConfirmClick}>Confirm</button>

      {/* Show popup when booking is confirmed */}
      {showPopup && (
        <div className="popup">
          <p>Booking Confirmed!</p>
        </div>
      )}
    </div>
  );
}
