import React, { useState } from "react";
import "../css/ParentLogin.css"; // Optionally include styles
import Navbar from "../components/NavBar";
import HomeFooter from "../components/HomeFooter";
import axios from "axios";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../services/firebase";
import { useNavigate } from "react-router-dom";

const ParentLogin = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [notification, setNotification] = useState(""); // State for notification

  const handleLogin = async (e) => {
    e.preventDefault();
    console.log("Logging in with:", { email, password });
    try {
      // Use Firebase's sign-in function
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      // Successfully logged in
      console.log("Login successful:", userCredential.user.uid);
      const response = await axios.get(
        `http://localhost:5001/api/auth/parent/parentData/${userCredential.user.uid}`
      );
      localStorage.setItem("user_uid", response.data.uid);
      localStorage.setItem("userType", response.data.userType);
      console.log("Parent data:", response.data);

      // Show notification for 2 seconds
      setNotification(`LogIn successful! Welcome ${response.data.fname}`);
      setTimeout(() => {
        setNotification(""); // Clear notification
        navigate("/parent-profile"); // Navigate to home page
      }, 2000);

      // You can now access user information or redirect as needed
    } catch (err) {
      console.error("Login failed:", err.message);
      alert("Login failed: " + err.message || "Invalid credentials");
    }
  };

  const navToSignUp = () => {
    navigate("/parentSign");
  };

  // const handleForgotPassword = () => {
  // Handle forgot password logic here, like navigating to a forgot password page
  // console.log("Forgot Password clicked");
  // };

  return (
    <div>
      <header>
        <Navbar />
      </header>
      <main className="maincontentWrapperPLogin">
        <div className="contentWrapperPLogin">
          <h1>Parent / Guardian Login</h1>
          {notification && (
            <div className="notification">{notification}</div> // Notification display
          )}
          <div className="Parentlogin-container">
            <form action="POST" onSubmit={handleLogin}>
              <p>
                <strong>Welcome to LearnEZ!</strong> <br /> Please log in using your email and
                password to access your parent dashboard.
              </p>
              <div className="input-group">
                <label>Email:</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  placeholder="Enter your email"
                />
              </div>
              <div className="input-group">
                <label>Password:</label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  placeholder="Enter your password"
                />
              </div>
              <button type="submit" className="login-btn" onClick={handleLogin}>
                Login in to <span>LearnEZ</span>
              </button>
            </form>
            {/* <button
              onClick={handleForgotPassword}
              className="forgot-password-btn"
            >
              Forgot Password?
            </button> */}

            <button className="forgot-password-btn" onClick={navToSignUp}>
              Create an account!
            </button>
          </div>
          <p className="termsServiceParentlogin">
            Terms of Service and Privacy Policy <br />
            Copyright © Mind InFormation Inc. 2024 - All Rights Reserved
          </p>
        </div>
      </main>
      <footer>
        <HomeFooter />
      </footer>
    </div>
  );
};

export default ParentLogin;

// command testing
