import React from 'react';
import '../Button/Button.css'
import { useNavigate } from 'react-router-dom';

export default function ButtonChildExercises() {
    const navigate = useNavigate();

    const handleClickExecisesListings = () => {
        navigate('/execises-listings'); // Update with your actual route path
    };
    return (
        <div className="ButtonChildExercises"> 
            <button onClick={handleClickExecisesListings}>Exercises</button>
        </div>
    );
}