import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Navbar from "../components/NavBar";
import Footer from "../components/footer";
import "../css/RegisterPages.css";

const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

const TutorEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [expandedDay, setExpandedDay] = useState(null);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    city: "",
    state: "",
    postalCode: "",
    phone: "",
    birthDate: "",
    certificateNumber: "",
    subjects: "",
    experience: "",
    specialization: "",
    availability: {},
    qualifiedTeacher: false,
    aboutMe: "",
    profileImage: null,
  });
  const [errorMessage, setErrorMessage] = useState("");

  // Cargar datos del tutor existente al montar el componente
  useEffect(() => {
    const fetchTutorDetails = async () => {
      try {
        const response = await fetch(`http://localhost:5001/api/tutors/${id}`);
        const data = await response.json();
        setFormData((prevData) => ({
          ...prevData,
          ...data,
          availability: data.availability || {},
          profileImage: data.profileImage || null,
        }));
      } catch (error) {
        setErrorMessage("Error fetching tutor details.");
      }
    };
    fetchTutorDetails();
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      profileImage: e.target.files[0],
    });
  };

  const handleToggleDay = (day) => {
    setExpandedDay(expandedDay === day ? null : day);
  };

  const handleTimeChange = (day, index, field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      availability: {
        ...prevData.availability,
        [day]: prevData.availability[day].map((slot, i) =>
          i === index ? { ...slot, [field]: value } : slot
        ),
      },
    }));
  };

  const addTimeSlot = (day) => {
    setFormData((prevData) => ({
      ...prevData,
      availability: {
        ...prevData.availability,
        [day]: [...(prevData.availability[day] || []), { start: "", end: "" }],
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      Object.keys(formData).forEach((key) => {
        if (key === "profileImage" && formData[key]) {
          formDataToSend.append(key, formData[key]);
        } else {
          formDataToSend.append(key, JSON.stringify(formData[key]));
        }
      });

      const response = await fetch(`http://localhost:5001/api/tutors/${id}`, {
        method: "PUT",
        body: formDataToSend,
      });

      if (!response.ok) {
        throw new Error("Failed to update tutor data");
      }

      navigate(`/tutor-profile/${id}`);
    } catch (error) {
      setErrorMessage("Failed to update tutor data.");
    }
  };

  return (
    <div>
      <header>
        <Navbar />
      </header>
      <main>
        <div className="register-container">
          <h2>Edit Tutor Profile</h2>
          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
          <form
            className="register-form"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <div className="input-group">
              <label>Your Name:</label>
              <div className="name-fields">
                <input
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  value={formData.firstName}
                  onChange={handleChange}
                />
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  value={formData.lastName}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="input-group">
              <label>Email:</label>
              <input
                type="email"
                name="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>

            <div className="input-group">
              <label>City:</label>
              <input
                type="text"
                name="city"
                value={formData.city}
                onChange={handleChange}
              />
              <label>State / Province:</label>
              <input
                type="text"
                name="state"
                value={formData.state}
                onChange={handleChange}
              />
            </div>

            <div className="input-group">
              <label>Phone Number:</label>
              <input
                type="text"
                name="phone"
                placeholder="Enter your phone number"
                value={formData.phone}
                onChange={handleChange}
              />
            </div>

            <div className="input-group">
              <label>Date of Birth:</label>
              <input
                type="date"
                name="birthDate"
                value={formData.birthDate}
                onChange={handleChange}
              />
            </div>

            <div className="input-group">
              <label>Certificate Number:</label>
              <input
                type="text"
                name="certificateNumber"
                value={formData.certificateNumber}
                onChange={handleChange}
              />
            </div>

            <div className="input-group">
              <label>Subject(s) and Levels Offered:</label>
              <textarea
                name="subjects"
                value={formData.subjects}
                onChange={handleChange}
              ></textarea>
            </div>

            <div className="input-group">
              <label>Relevant Experience (Teaching/Tutoring):</label>
              <textarea
                name="experience"
                value={formData.experience}
                onChange={handleChange}
              ></textarea>
            </div>

            <div className="input-group">
              <label>Specialization:</label>
              <input
                type="text"
                name="specialization"
                placeholder="Specialization"
                value={formData.specialization}
                onChange={handleChange}
              />
            </div>

            {/* Disponibilidad */}
            <div className="input-group">
              <label>Availability:</label>
              {daysOfWeek.map((day) => (
                <div key={day} className="day-availability">
                  <button type="button" onClick={() => handleToggleDay(day)}>
                    {day}
                  </button>
                  {expandedDay === day && (
                    <div className="time-slots">
                      {(formData.availability[day] || []).map((timeSlot, index) => (
                        <div key={index} className="time-slot">
                          <input
                            type="time"
                            placeholder="Start Time"
                            value={timeSlot.start}
                            onChange={(e) =>
                              handleTimeChange(day, index, "start", e.target.value)
                            }
                          />
                          <input
                            type="time"
                            placeholder="End Time"
                            value={timeSlot.end}
                            onChange={(e) =>
                              handleTimeChange(day, index, "end", e.target.value)
                            }
                          />
                        </div>
                      ))}
                      <button type="button" onClick={() => addTimeSlot(day)}>
                        Add Time Slot
                      </button>
                    </div>
                  )}
                </div>
              ))}
            </div>

            <div className="input-group">
              <label>Profile Image:</label>
              <input
                type="file"
                name="profileImage"
                onChange={handleFileChange}
                accept="image/*"
              />
            </div>

            <div className="input-group">
              <label>About Me:</label>
              <textarea
                name="aboutMe"
                value={formData.aboutMe}
                onChange={handleChange}
                placeholder="Write a brief description about yourself"
              ></textarea>
            </div>

            <div className="input-group">
              <label>Qualified Teacher Status:</label>
              <input
                type="checkbox"
                name="qualifiedTeacher"
                checked={formData.qualifiedTeacher}
                onChange={handleChange}
              />{" "}
              Yes
            </div>

            <button type="submit">Save Changes</button>
          </form>
        </div>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default TutorEdit;