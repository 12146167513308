import React from 'react';
import '../Button/Button.css'
import { useNavigate } from 'react-router-dom';

export default function ButtonContactUs() {
    const navigate = useNavigate();

    const handleClickContactUs = () => {
        navigate('/contact'); // Update with your actual route path
    };
    
    return (
        <div className="buttonContactUs"> 
            <button onClick={handleClickContactUs}>Contact Us</button>
        </div>
    );
}


