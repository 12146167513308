import React, { useState, useEffect } from "react";
import appleImage from "../../assets/exerSVG/apple.svg";
import "../../css/exercise/exerciseCop05.css";

export const ComponentFive = ({ setScore }) => {
  const targetNumbers = [5, 3]; // Target numbers for each column
  const [correctNumbers, setCorrectNumbers] = useState([null, null]); // User-selected numbers
  const [selectedNumber, setSelectedNumber] = useState(null);
  const [dropStatus, setDropStatus] = useState(["", ""]); // Status for feedback styling
  const [scoreSet, setScoreSet] = useState(false); // New state to control score updates

  const numbers = [1, 2, 3, 4, 5];

  const handleDragStart = (number) => {
    setSelectedNumber(number);
  };

  const handleDrop = (index) => {
    if (selectedNumber !== null) {
      const isCorrect = selectedNumber === targetNumbers[index];
      setCorrectNumbers((prevNumbers) => {
        const newNumbers = [...prevNumbers];
        newNumbers[index] = selectedNumber;
        return newNumbers;
      });

      setDropStatus((prevStatus) => {
        const newStatus = [...prevStatus];
        newStatus[index] = isCorrect ? "correct" : "incorrect";
        return newStatus;
      });

      setSelectedNumber(null);
    }
  };

  // Calculate and set score after both columns have been filled
  useEffect(() => {
    const allDropped = correctNumbers.every((number) => number !== null);
    const allCorrect = dropStatus.every((status) => status === "correct");

    if (allDropped && !scoreSet) {
      setScore(allCorrect ? 1 : 0);
      setScoreSet(true); // Prevent further updates to score
    }
  }, [correctNumbers, dropStatus, scoreSet, setScore]);

  return (
    <div className="exercise-five">
      {/* Column 1 */}
      <div className="column">
        <h3>Column 1</h3>
        <div className="apples">
          {Array.from({ length: targetNumbers[0] }, (_, i) => (
            <img key={i} src={appleImage} alt="Apple" className="apple-image" />
          ))}
          <div
            className={`drop-area ${dropStatus[0]}`}
            onDragOver={(e) => e.preventDefault()}
            onDrop={() => handleDrop(0)}
          >
            {correctNumbers[0] !== null ? (
              <span className="dropped-number">{correctNumbers[0]}</span>
            ) : (
              <span>Drop number here</span>
            )}
          </div>
        </div>
      </div>

      {/* Column 2 */}
      <div className="column">
        <h3>Column 2</h3>
        <div className="apples">
          {Array.from({ length: targetNumbers[1] }, (_, i) => (
            <img key={i} src={appleImage} alt="Apple" className="apple-image" />
          ))}
          <div
            className={`drop-area ${dropStatus[1]}`}
            onDragOver={(e) => e.preventDefault()}
            onDrop={() => handleDrop(1)}
          >
            {correctNumbers[1] !== null ? (
              <span className="dropped-number">{correctNumbers[1]}</span>
            ) : (
              <span>Drop number here</span>
            )}
          </div>
        </div>
      </div>

      {/* Number selection area */}
      <div className="number-selection">
        {numbers.map((number) => (
          <div
            key={number}
            draggable
            onDragStart={() => handleDragStart(number)}
            className="number-item"
          >
            {number}
          </div>
        ))}
      </div>
    </div>
  );
};
