import React from "react";
import Navbar from "../components/NavBar";
import Footer from "../components/HomeFooter";
import AboutUsHero from "../components/AboutUsHero";
import TeamMembers from "../components/TeamMembers";
import Suggestion from "../components/AboutUsContact";

const AboutUs = () => {
  return (
    <div className="AboutUsDiv">
      <Navbar />
      <AboutUsHero />
      <TeamMembers />
      <Suggestion />
      <Footer />
    </div>
  );
};

export default AboutUs;
