import React, { useState } from "react";
import catImage from "../../assets/exerSVG/stupid_cat.svg"; // Updated to cat image
import { FaMicrophone } from "react-icons/fa"; // Import microphone icon
import "../../css/exercise/exerciseCop04.css";

export const ComponentFour = ({ setScore }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [spokenText, setSpokenText] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const SpeechRecognition =
    window.SpeechRecognition || window.webkitSpeechRecognition;
  const recognitionInstance = SpeechRecognition
    ? new SpeechRecognition()
    : null;

  const startRecording = () => {
    if (recognitionInstance) {
      setIsRecording(true);
      setFeedback("");
      setSpokenText("");
      setIsProcessing(true);
      setScore(1);

      recognitionInstance.start();
      recognitionInstance.onresult = (event) => {
        const text = event.results[0][0].transcript.toLowerCase();
        setSpokenText(text);
        checkAnswer(text);
        setIsProcessing(false);
        setScore(0);
      };

      recognitionInstance.onend = () => {
        setIsRecording(false);
        setIsProcessing(false);
      };
    }
  };

  const checkAnswer = (text) => {
    if (text === "meow") {
      setFeedback("Hurrah! You Got it! 🤩");
    } else {
      setFeedback("Whoops! That not what the cat say! 🤭");
    }
  };

  return (
    <div className="exercise-four">
      <img src={catImage} alt="Cat" className="cat-image" />
      <h3>What does the cat say...!</h3>
      <button
        onClick={startRecording}
        disabled={isRecording}
        className="record-btn"
      >
        <FaMicrophone className="microphone-icon" />
      </button>

      {isProcessing && (
        <div className="processing-animation">Processing...</div>
      )}
      {feedback && <span className="feedback-text">{feedback}</span>}
      {spokenText && (
        <p className="recognized-text">You said: "{spokenText}"</p>
      )}
    </div>
  );
};
