import React from "react";
import Header from "../components/Header";
import HomeFooter from "../components/HomeFooter";
import ZoomWaitingRoom from "../components/ZoomWaitingRoom";

const ZoomWaitingLink = () => {
    return ( 
        <>
        <Header/>
        <ZoomWaitingRoom/>
        <HomeFooter/>
        </>
     );
}
 
export default ZoomWaitingLink;