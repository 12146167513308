import React, { useState, useRef } from "react";
import "../../css/exercise/exerciseCop07.css"; // Link to your CSS file

export const ComponentSeven = ({ setScore }) => {
  const [traceStatus, setTraceStatus] = useState(null); // null, "correct", or "incorrect"
  const [isTracing, setIsTracing] = useState(false);
  const traceRef = useRef([]);

  const handleMouseDown = () => {
    // Only allow tracing if there is no result yet
    if (traceStatus === null) {
      setIsTracing(true);
      traceRef.current = []; // Reset the tracing path
    }
  };

  const handleMouseMove = (event) => {
    if (!isTracing) return;
    const rect = event.currentTarget.getBoundingClientRect();
    const x = event.clientX - rect.left; // Get x coordinate
    const y = event.clientY - rect.top; // Get y coordinate
    traceRef.current.push({ x, y }); // Store the tracing path
  };

  const handleMouseUp = () => {
    setIsTracing(false);
    const isCorrect = checkTraceCorrectness();
    setTraceStatus(isCorrect ? "correct" : "incorrect");
    setScore(isCorrect ? 1 : 0);
  };

  const checkTraceCorrectness = () => {
    // Define the square path in a clockwise direction
    const correctPath = [
      { x: 50, y: 50 }, // Top-left
      { x: 250, y: 50 }, // Top-right
      { x: 250, y: 250 }, // Bottom-right
      { x: 50, y: 250 }, // Bottom-left
      { x: 50, y: 50 }, // Back to Top-left to close the square
    ];

    const tolerance = 12; // Allowable distance from the line
    let pathIndex = 0; // To track the segment of the path being checked

    for (let i = 0; i < traceRef.current.length; i++) {
      const point = traceRef.current[i];

      // Check if the point is near the current line segment
      const nextPoint = correctPath[pathIndex + 1] || correctPath[0];

      if (
        isPointNearLineSegment(
          point,
          correctPath[pathIndex],
          nextPoint,
          tolerance
        )
      ) {
        // Move to the next segment if we are near the current one
        if (pathIndex < correctPath.length - 1) {
          pathIndex++;
        }
      } else {
        return false; // If we stray too far from the line, return incorrect
      }
    }

    return true; // If all points are close to the line, return correct
  };

  // Function to check if a point is near a line segment
  const isPointNearLineSegment = (point, lineStart, lineEnd, tolerance) => {
    const { x: px, y: py } = point;
    const { x: x1, y: y1 } = lineStart;
    const { x: x2, y: y2 } = lineEnd;

    const lineLengthSquared = (x2 - x1) ** 2 + (y2 - y1) ** 2;

    if (lineLengthSquared === 0) return false; // Prevent division by zero

    const t = Math.max(
      0,
      Math.min(
        1,
        ((px - x1) * (x2 - x1) + (py - y1) * (y2 - y1)) / lineLengthSquared
      )
    );
    const closestX = x1 + t * (x2 - x1);
    const closestY = y1 + t * (y2 - y1);

    const distanceSquared = (px - closestX) ** 2 + (py - closestY) ** 2;

    return distanceSquared <= tolerance ** 2; // Check if within tolerance
  };

  // Determine the stroke color based on trace status
  const strokeColor =
    traceStatus === "correct"
      ? "green"
      : traceStatus === "incorrect"
      ? "red"
      : "black";

  return (
    <div className="component-seven">
      <h3>Trace the dotted line in a clockwise direction</h3>
      <div
        className={`trace-box ${traceStatus}`}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        style={{ position: "relative" }}
      >
        <svg
          width="300px"
          height="270px"
          style={{ position: "absolute", top: 0, left: 0 }}
        >
          <polygon
            points="50,50 250,50 250,250 50,250"
            stroke={strokeColor} // Use dynamic stroke color
            strokeWidth="7" // Increased line size
            fill="none"
            strokeDasharray="12,15" // Increased dot size
          />
        </svg>
      </div>
      {traceStatus === "correct" && <p className="feedback">Great job!</p>}
      {traceStatus === "incorrect" && (
        <p className="feedback">Sorry, the line didn't match!</p>
      )}
    </div>
  );
};
