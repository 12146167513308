import React from "react";
import "../css/TrainingTutorCard.css";
import imgJohn from "../assets/images/John.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";

export default function TrainingTutorCard() {
  return (
    <div className="TrainingTutorCard">
      <div className="TrainingTutorCard1">
        <h4 className="tutor-Montserrat">Training with</h4>
        <p>John Doe</p>
      </div>
      <div className="TrainingTutorCard2">
        <img src={imgJohn} alt="Man" />
        <div className="TrainingTutorCard3">
          <div className="TrainingTutorCard5">
            <p>Wed</p>
            <p className="TrainingTutorCard5P">2014/11/28</p>
          </div>
          <div className="TrainingTutorCard4">
            <FontAwesomeIcon icon={faClock} />
            <p>9:00am to 9:30am</p>
          </div>
          <div>
            <p>30 mins class John &lt;&gt; Ryan</p>
          </div>
          <div className="TrainingZoomLink">
            <a href="https//www.zoom.com/">Zoom Link</a>
          </div>
        </div>
      </div>
    </div>
  );
}
